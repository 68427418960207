import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BuilderScreen } from "./UI/BuilderScreen/BuilderScreen";
import { BuilderUI } from "./UI/BuilderUI/BuilderUI";
import Scene from "./r3f/Scene.js";
import { isIE } from "react-device-detect";
import { OverlayBadBrowsers } from "./UI/OverlayBadBrowsers/OverlayBadBrowsers";
import NetworkError from "./UI/NetworkError";

export default function ProductBuilder() {
  const [isOverlayVisible, setIsOverlayVisible] = React.useState(isIE || false);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#c71608",
      },
    },
  });

  return (
    <>
      <ThemeProvider theme={theme}>
        <BuilderScreen>
          <NetworkError />
          {/* ovelay to inform users of issues & stop them from using the site on IE */}
          {isOverlayVisible && <OverlayBadBrowsers isOverlayVisible={isOverlayVisible} setIsOverlayVisible={setIsOverlayVisible} />}
          {/* continue with regular experience */}
          {!isOverlayVisible && (
            <>
              <BuilderUI />
              <Scene />
            </>
          )}
        </BuilderScreen>
      </ThemeProvider>
    </>
  );
}
