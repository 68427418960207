import { useEffect, useRef, useMemo, useState } from "react";
import sift from "sift";
import { CardElementEngravings } from "../CardElement/CardElementEngravings";
import { FontSection } from "../FontSection/FontSection";
import { Tabs } from "../Tabs/Tabs";
import { ColorSection } from "../ColorSection/ColorSection";
import { InputBox } from "../InputBox/InputBox";
import { DecalsSection } from "../DecalsSection/DecalsSection";
import { FileDragDrop } from "../FileDragDrop/FileDragDrop";
import { getActiveEngravingSectionId } from "../../../modules/getActiveEngravingSectionId";
import { BottomNavigation } from "../BuildSection/BottomNavigation";
import { useComponentsIndex } from "../../../hooks/useComponentsIndex";

export const Engravings = ({ productsState, componentsStateObj, itemsStateObj }) => {
  const [isTabsRequired, setIsTabsRequired] = useState(true);
  const meshEditingStatus = componentsStateObj.state.activeObj.mesh_editing_status;
  const engravingCards = componentsStateObj.state.activeObj.sections;
  const { engravingsIndex } = useComponentsIndex(componentsStateObj.state);
  const activeEngravingSectionId = getActiveEngravingSectionId(meshEditingStatus, itemsStateObj.state, componentsStateObj.state);
  const isFirstLoad = useRef(true);
  const fontSection = useMemo(() => {
    return itemsStateObj.state.array.filter(sift({ _id: { $in: componentsStateObj.state.activeObj.items }, section: { $eq: "text_engraving" } }));
  }, []);
  const colorsSection = useMemo(() => {
    return itemsStateObj.state.array.filter(sift({ _id: { $in: componentsStateObj.state.activeObj.items }, section: { $eq: "colors" } })).sort((a, b) => {
      return componentsStateObj.state.activeObj.items.indexOf(a._id) - componentsStateObj.state.activeObj.items.indexOf(b._id);
    });
  }, []);
  const decalsSection = useMemo(() => {
    return itemsStateObj.state.array
      .filter(sift({ _id: { $in: componentsStateObj.state.activeObj.items }, section: { $eq: "logo_engraving" }, svg_src: { $exists: true } }))
      .sort((a, b) => {
        return componentsStateObj.state.activeObj.items.indexOf(a._id) - componentsStateObj.state.activeObj.items.indexOf(b._id);
      });
  }, []);

  useEffect(() => {
    // if only a front cover is available
    if (!productsState.activeObj.plates.rear) {
      const copyComponentState = { ...componentsStateObj.state };
      copyComponentState.activeObj.mesh_editing_status = "front";

      componentsStateObj.setState(copyComponentState);
      setIsTabsRequired(false);
    }
    // only rear is available
    else if (!productsState.activeObj.plates.front) {
      const copyComponentState = { ...componentsStateObj.state };
      copyComponentState.activeObj.mesh_editing_status = "rear";

      componentsStateObj.setState(copyComponentState);
      setIsTabsRequired(false);
    }
  }, [productsState.activeId]);

  useEffect(() => {
    //update rear cover to match front when meshEditingStutus is both
    if (!isFirstLoad.current && meshEditingStatus === "both") {
      let copyItemsStateActiveIds = { ...itemsStateObj.state.activeIds };

      // engravings color
      copyItemsStateActiveIds[componentsStateObj.state.activeId].rear.color = {
        ...itemsStateObj.state.activeIds[componentsStateObj.state.activeId].front.color,
      };
      // engravings type
      copyItemsStateActiveIds[componentsStateObj.state.activeId].rear.engraving = matchEngravingsFactory(
        copyItemsStateActiveIds,
        componentsStateObj.state,
        copyItemsStateActiveIds[componentsStateObj.state.activeId].front.engraving._id
      );

      itemsStateObj.setActiveId(copyItemsStateActiveIds);
      return;
    }

    isFirstLoad.current = false;
  }, [meshEditingStatus]);

  if (itemsStateObj.state?.activeIds?.baseColor_default?._id === "lit_baseColor") {
    return <div className="text-center mt-4">Engravings are not compatible with Laser Imbedded Technology</div>;
  }

  return (
    <div className="mt-3">
      {isTabsRequired && <Tabs tabLabels={["front", "both", "rear"]} componentsStateObj={componentsStateObj} />}
      <CardElementEngravings cardContentArray={engravingCards} cardsPerRow={3} itemsStateObj={itemsStateObj} componentsState={componentsStateObj.state} />

      {activeEngravingSectionId === "logo_engraving" && (
        <>
          <DecalsSection decalsData={decalsSection} componentsState={componentsStateObj.state} title="Stock Engraving:" itemsStateObj={itemsStateObj} />
          <ColorSection
            colorData={colorsSection}
            componentsState={componentsStateObj.state}
            title="Choose Engraving Color:"
            itemsStateObj={itemsStateObj}
            additionalPrice={
              itemsStateObj.state.activeObjs[componentsStateObj.state.array[engravingsIndex]._id][meshEditingStatus === "both" ? "front" : meshEditingStatus]
                .color.additional_price
            }
          />
          <FileDragDrop title="Custom Engraving" componentsState={componentsStateObj.state} itemsStateObj={itemsStateObj} additionalPrice={true} />
        </>
      )}

      {activeEngravingSectionId === "text_engraving" && (
        <>
          <InputBox inputName="customText" title="Enter Text" itemsStateObj={itemsStateObj} componentsState={componentsStateObj.state} additionalPrice={75} />
          <FontSection
            customTextData={fontSection[meshEditingStatus === "rear" ? 1 : 0]}
            componentsState={componentsStateObj.state}
            itemsStateObj={itemsStateObj}
            title="Choose Font:"
          />
          <ColorSection
            colorData={colorsSection}
            componentsState={componentsStateObj.state}
            title="Choose Color:"
            itemsStateObj={itemsStateObj}
            additionalPrice={
              itemsStateObj.state.activeObjs[componentsStateObj.state.array[engravingsIndex]._id][meshEditingStatus === "both" ? "front" : meshEditingStatus]
                .color.additional_price
            }
          />
        </>
      )}
      <BottomNavigation componentsStateObj={componentsStateObj} />
    </div>
  );
};

// returns rear engravings data that matches front engravings data
const matchEngravingsFactory = (copyItemsStateActiveIds, componentsState, frontEngravingId) => {
  switch (frontEngravingId) {
    case "custom_logo_front":
      let newRearObj = {
        _id: "custom_logo_rear",
        inputs: { uploaded_logo_src: copyItemsStateActiveIds[componentsState.activeId].front.engraving.inputs.uploaded_logo_src },
      };
      if (newRearObj.inputs?.uploaded_logo_src?.slice(0, 5) === "temp-") newRearObj.inputs.uploaded_logo_src = `temp-${Math.random()}`;
      return newRearObj;
      break;
    case "custom_text_front":
      return { ...copyItemsStateActiveIds[componentsState.activeId].front.engraving, _id: "custom_text_rear" };
      break;
    default:
      return { ...copyItemsStateActiveIds[componentsState.activeId].front.engraving };
      break;
  }
};
