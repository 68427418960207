import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import { components_state } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
import EmbedController from "../../../../embed/EmbedController";
import "./styles.scss";

export const BuildSection = ({ children }) => {
  // scroll to top when active component changes
  const [componentsState] = useAtom(components_state);
  const elRef = useRef();
  useEffect(() => {
    elRef.current.scrollTop = 0;
  }, [componentsState.activeId]);

  const handleClick = () => {
    let windowReference = window.open();
    windowReference.location = "https://tastetest3d.com/";

    if (EmbedController.isEmbedded) {
      EmbedController.sendGTMAnalyticsEvent({
        event: "Powered By TT Click - MGP",
      });
    }
  };

  return (
    <Container id="BuildSection" ref={elRef} fluid className="d-flex flex-column bg-white LeftComponentDrawer">
      {children}

      <div role="button" onClick={handleClick} className="tastetest-brandmark d-flex mt-auto mb-1 align-items-center justify-content-center">
        <p className="m-0">powered by</p>
        <img src="/images/TasteTest_BrandMark_Gradient.png" alt="Taste Test" />
      </div>
    </Container>
  );
};

BuildSection.propTypes = {
  children: PropTypes.node,
};
