/** Loops through itemsState.activeObjs to organize cartData
 * cartData then gets fed into cards
 */

export const getShoppingCartData = (productsState, componentsState, itemsState, componentsIndexObj) => {
  // drill into component sections to get pricing
  const [baseColorSection] = componentsState.array[componentsIndexObj.baseColorDefaultIndex].sections.filter(
    (item) => item._id === itemsState.activeObjs.baseColor_default.section
  );
  // engraving pricing is done at an item level
  const engravingsActiveObj = itemsState.activeObjs[componentsState.array[componentsIndexObj.engravingsIndex]._id];

  let hasAdditionalPriceForColorBeenAdded = false;

  let cartData = [];
  const iterate = (itemsStateActiveObjs) => {
    Object.keys(itemsStateActiveObjs).forEach((key) => {
      let cartItemObj = {};

      // drill into nested objects (i.e. engravings)
      if (typeof itemsStateActiveObjs[key] === "object" && itemsStateActiveObjs[key] !== null) {
        iterate(itemsStateActiveObjs[key]);
      }

      if (key === "baseColor_default") {
        cartItemObj._id = itemsStateActiveObjs[key]._id;
        cartItemObj.title = "Color";
        cartItemObj.description = itemsStateActiveObjs[key].displayName;
        cartItemObj.previewImg = itemsStateActiveObjs[key]?.image_url ?? null;
        cartItemObj.previewCustomColor = itemsStateActiveObjs[key]?.inputs?.color ?? null;
        cartItemObj.paintName = itemsStateActiveObjs[key].paintName ?? null;
        cartItemObj.paintCode = itemsStateActiveObjs[key].paintCode ?? null;
        cartItemObj.uploadedLogoBase64 = itemsStateActiveObjs[key].uploaded_logo_base64 ?? null;
        cartItemObj.uploadedLogoSrc = itemsStateActiveObjs[key].uploaded_logo_src ?? null;
        cartItemObj.previewText = null;
        cartItemObj.price = baseColorSection.additional_price;
        cartItemObj.section = "color";
        cartData.push(cartItemObj);
      } else if (key === "bolts") {
        cartItemObj._id = itemsStateActiveObjs[key]._id;
        cartItemObj.title = "Bolts";
        cartItemObj.description = itemsStateActiveObjs[key].displayName;
        cartItemObj.boltsColor = itemsStateActiveObjs[key]._id === "bolts_on" ? itemsStateActiveObjs.color.displayName : null;
        cartItemObj.previewImg = itemsStateActiveObjs[key]._id === "bolts_on" ? "/mgp-caliper-assets/textures/bolts/bolt.svg" : "/images/iconBoltsOff.svg";
        cartItemObj.previewText = null;
        cartItemObj.price = itemsStateActiveObjs[key].additional_price;
        cartItemObj.section = "bolts";
        cartData.push(cartItemObj);
      } else if (key === "front") {
        cartItemObj._id = itemsStateActiveObjs[key].engraving._id;
        cartItemObj.title = "Engravings - Front";
        cartItemObj.description = itemsStateActiveObjs[key].engraving._id === "custom_logo_front" ? "Custom" : itemsStateActiveObjs[key].color.displayName;
        cartItemObj.previewImg =
          itemsStateActiveObjs[key].engraving._id === "custom_logo_front"
            ? getCustomLogoSrc(itemsStateActiveObjs[key].engraving)
            : itemsStateActiveObjs[key].engraving.svg_src;
        cartItemObj.previewText = itemsStateActiveObjs[key].engraving?.text_input ?? null;
        cartItemObj.price = engravingsActiveObj.front.engraving.additional_price;
        cartItemObj.section = "engravings";
        cartItemObj.engravingType =
          itemsStateActiveObjs[key].engraving._id === "custom_logo_front" || itemsStateActiveObjs[key].engraving?.text_input
            ? itemsStateActiveObjs[key].engraving._id
            : "decalEngraving";
        cartData.push(cartItemObj);
      } else if (key === "rear") {
        cartItemObj._id = itemsStateActiveObjs[key].engraving._id;
        cartItemObj.title = "Engravings - Rear";
        cartItemObj.description = itemsStateActiveObjs[key].engraving._id === "custom_logo_rear" ? "Custom" : itemsStateActiveObjs[key].color.displayName;
        cartItemObj.previewImg =
          itemsStateActiveObjs[key].engraving._id === "custom_logo_rear"
            ? getCustomLogoSrc(itemsStateActiveObjs[key].engraving)
            : itemsStateActiveObjs[key].engraving.svg_src;
        cartItemObj.previewText = itemsStateActiveObjs[key].engraving?.text_input ?? null;
        cartItemObj.price = engravingsActiveObj.rear.engraving.additional_price;
        cartItemObj.section = "engravings";
        cartItemObj.engravingType =
          itemsStateActiveObjs[key].engraving._id === "custom_logo_rear" || itemsStateActiveObjs[key].engraving?.text_input
            ? itemsStateActiveObjs[key].engraving._id
            : "decalEngraving";
        cartData.push(cartItemObj);
      }

      // handle additional price for fill colors that are extra
      else if (
        !hasAdditionalPriceForColorBeenAdded &&
        key === "color" &&
        itemsStateActiveObjs[key].additional_price &&
        itemsState.activeObjs["baseColor_default"]._id !== "lit_baseColor"
      ) {
        cartItemObj.isAdditionalPriceForColor = true;
        cartItemObj.price = itemsStateActiveObjs[key].additional_price;
        hasAdditionalPriceForColorBeenAdded = true;
        cartData.push(cartItemObj);
      }
    });
  };

  iterate(itemsState.activeObjs);
  cartData.reorderArray(cartData.length - 1, 1);
  cartData = moveAdditionalPriceObjectToLast(cartData);

  // remove engraving card if there is no engraving
  if (engravingsActiveObj?.front?.engraving._id === "no_engraving" || engravingsActiveObj?.rear?.engraving._id === "no_engraving") {
    cartData = cartData.filter((item) => item._id !== "no_engraving");
  }

  return cartData;
};

function moveAdditionalPriceObjectToLast(array) {
  let objectToMove = null;
  let indexToRemove = -1;

  // Find the object with isAdditionalPriceForColor = true and its index
  for (let i = 0; i < array.length; i++) {
    if (array[i].isAdditionalPriceForColor === true) {
      objectToMove = array[i];
      indexToRemove = i;
      break;
    }
  }

  // If the object is found, remove it and add it to the end of the array
  if (objectToMove !== null) {
    array.splice(indexToRemove, 1); // Remove the object from its current position
    array.push(objectToMove); // Add the object at the end of the array
  }

  return array;
}

// rearranges the array to present cards in certain order
Array.prototype.reorderArray = function (from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

function getCustomLogoSrc(engravingObj) {
  return engravingObj.uploaded_logo_src.includes("http") ? engravingObj.uploaded_logo_src : engravingObj.uploaded_logo_base64;
}
