import { useCallback } from "react";
import { getStepperActiveIndex } from "../../../modules/getStepperActiveIndex";
import { Boop } from "../Animations/Boop";
import EmbedController from "../../../../embed/EmbedController";

// export const Navigation = ({ state, setState }) => {
export const Navigation = (props) => {
  const activeComponentIndex = getStepperActiveIndex(props.state);

  const handlePrevious = useCallback(() => {
    if (activeComponentIndex !== 0) {
      props.setState(props.state.array[activeComponentIndex - 1]._id);

      if (EmbedController.isEmbedded) {
        EmbedController.sendGTMAnalyticsEvent({
          event: "Navigation Flag Button Click - MGP",
          flagLabel: "Previous",
          page: props.state.activeId.split("_")[0],
        });
      }
    }
  }, [props.state.activeId]);

  const handleNext = useCallback(() => {
    props.setState(props.state.array[activeComponentIndex + 1]._id);

    if (EmbedController.isEmbedded) {
      EmbedController.sendGTMAnalyticsEvent({
        event: "Navigation Flag Button Click - MGP",
        flagLabel: activeComponentIndex === 2 ? "Cart" : "Next",
        page: props.state.activeId.split("_")[0],
      });
    }
  }, [props.state.activeId]);

  const stickyNavRef = useCallback((node) => {
    if (typeof IntersectionObserver === "undefined") return;
    // apply className isSticking to navigation when it intersects with the top of the page
    const observer = new IntersectionObserver(([e]) => e.target.classList.toggle("isSticking", e.intersectionRatio < 0.99), { threshold: [0.99], root: null });

    if (node && observer) {
      observer.observe(node);
    }
  }, []);

  return (
    <div ref={stickyNavRef} className="navigation sticky pt-1 pb-1 mt-2 mb-2 d-flex align-items-center justify-content-center px-0">
      <Boop boopType="scale" scale={activeComponentIndex === 0 ? "1" : "1.1"} timing={200} disabled={activeComponentIndex === 0}>
        <button className={`arrow-button prev`} onClick={handlePrevious} disabled={activeComponentIndex === 0}>
          prev
        </button>
      </Boop>

      {props.state.activeObj?.displayName && <h5 className="text-center pt-1 my-auto text-dark">{`Select ${props.state.activeObj.displayName}`}</h5>}

      <Boop boopType="scale" scale="1.1" timing={200}>
        <button className={`arrow-button next ${activeComponentIndex === 2 && 'active'}`} onClick={handleNext}>
          {activeComponentIndex === 2 ? "cart" : "next"}
        </button>
      </Boop>
    </div>
  );
};
