/**
 * This hook is used to organize the payload for the MGP cart.
 */
import { useAtom } from "jotai";
import EmbedController from "../../embed/EmbedController";
import { canvas_base64 } from "../components/dataManagers/GlobalDataManagers";
import { getFinalPrice } from "../modules/getFinalPrice";
import { useComponentsIndex } from "./useComponentsIndex";

export const useMgpCartKeys = (productsState, componentsState, itemsState, cartData) => {
  const { engravingsIndex } = useComponentsIndex(componentsState);
  const [custom_image_base64] = useAtom(canvas_base64);

  // defualt fields included in cartObj regardless of shopper custom choices
  let cartObj = {
    "options[pattern-front]": productsState.activeObj?.pass_through?.plate_code_front?.toLowerCase() || "",
    "options[pattern-rear]": productsState.activeObj?.pass_through?.plate_code_rear?.toLowerCase() || "",
    "options[color]": getFullColorString(itemsState.activeObjs.baseColor_default),
    "options[design-front-type]": getDesignType(componentsState, itemsState, engravingsIndex, "front"),
    "options[design-rear-type]": getDesignType(componentsState, itemsState, engravingsIndex, "rear"),
    "options[bolts-bolts-choice]": itemsState.activeObjs.bolts_default.bolts._id === "bolts_on" ? "WITH BOLTS" : "NO BOLTS",
    "options[part_number]": productsState.activeObj?.part_number?.slice(0, -1), // remove last letter of part_number to format it correctly
    "options[test-data]": "(customized)",
    ...productsState.activeObj?.pass_through,
    // id: productsState.activeObj?.pass_through?.id,
    // plate_code_front: productsState.activeObj?.pass_through?.plate_code_front,
    // plate_code_rear: productsState.activeObj?.pass_through?.plate_code_rear,
    // plate_number_front: productsState.activeObj?.pass_through?.plate_number_front,
    // plate_number_rear: productsState.activeObj?.pass_through?.plate_number_rear,
    // make: productsState.activeObj?.pass_through?.make,
    // model: productsState.activeObj?.pass_through?.model,
    // year: productsState.activeObj?.pass_through?.year,
    // submodel: productsState.activeObj?.pass_through?.submodel,
    // option: productsState.activeObj?.pass_through?.option || "",
    price: getFinalPrice(productsState, cartData),
    edit_url: EmbedController?.parentUrl || window.location.href,
    custom_image: custom_image_base64 || "data:image/png;base64,iV",
  };

  // add other fields to cart item depending upon shopper's choices
  cartObj = handleCustomBaseColor(itemsState, cartObj);
  cartObj = handleCustomFrontImage(itemsState, cartObj);
  cartObj = handleCustomRearImage(itemsState, cartObj);
  cartObj = handleDesignChoice(componentsState, engravingsIndex, cartObj);
  cartObj = handleFillColor(componentsState, itemsState, engravingsIndex, cartObj);
  cartObj = handleEngravingImage(componentsState, itemsState, engravingsIndex, cartObj);
  cartObj = handleBoltsColor(itemsState, cartObj);
  cartObj = handleCustomText(componentsState, engravingsIndex, itemsState, cartObj);

  return cartObj;
};

// helper
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

function getFullColorString(item) {
  if (item._id === "custom_baseColor") {
    return "paint_name (Paint match)";
  } else {
    let colorName = item.displayName;
    let colorSectionFormatted = `(${capitalizeFirstLetter(item.section.split("_")[0])})`;
    return `${colorName} ${colorSectionFormatted}`;
  }
}

function handleCustomBaseColor(itemsState, cartObj) {
  if (itemsState.activeObjs.baseColor_default._id === "custom_baseColor") {
    cartObj["options[paint_code]"] = itemsState.activeObjs.baseColor_default.paintCode;
    cartObj["options[paint_name]"] = itemsState.activeObjs.baseColor_default.paintName;
  } else if (itemsState.activeObjs.baseColor_default._id === "lit_baseColor") {
    cartObj["options[lit-texture-src]"] = itemsState.activeObjs.baseColor_default.uploaded_logo_src;
  }
  return cartObj;
}

function handleCustomFrontImage(itemsState, cartObj) {
  const engraving = Object.keys(itemsState.activeIds).find((key) => {
    if (key.includes("engravings")) return key;
    return false;
  });
  if (engraving && itemsState.activeObjs[engraving]?.front?.engraving.uploaded_logo_src) {
    cartObj["options[design-front-image-custom]"] = itemsState.activeObjs[engraving].front.engraving.uploaded_logo_src;
  }
  return cartObj;
}

function handleCustomRearImage(itemsState, cartObj) {
  const engraving = Object.keys(itemsState.activeIds).find((key) => {
    if (key.includes("engravings")) return key;
    return false;
  });
  if (engraving && itemsState.activeObjs[engraving]?.rear?.engraving.uploaded_logo_src) {
    cartObj["options[design-rear-image-custom]"] = itemsState.activeObjs[engraving].rear.engraving.uploaded_logo_src;
  }
  return cartObj;
}

const getDesignType = (componentsState, itemsState, engravingsIndex, position) => {
  let activeEngravingContainerObj = itemsState.activeObjs[componentsState.array[engravingsIndex]._id];

  if (activeEngravingContainerObj[position]?.engraving.section === "logo_engraving") return `Logo/Image (${capitalizeFirstLetter(position)})`;
  else if (activeEngravingContainerObj[position]?.engraving.section === "text_engraving") return `Text (${capitalizeFirstLetter(position)})`;
  else return `None (${capitalizeFirstLetter(position)})`;
};

// set color of front or rear engraving
// NOTE: this is submitted with a custom logo, even though it doesn't apply
const handleFillColor = (componentsState, itemsState, engravingsIndex, cartObj) => {
  let activeEngravingContainerObj = itemsState.activeObjs[componentsState.array[engravingsIndex]._id];

  // check for front plate before graving section _id
  if (activeEngravingContainerObj?.front && activeEngravingContainerObj.front?.engraving.section !== "no_engraving")
    cartObj["options[design-front-fill]"] = `${activeEngravingContainerObj.front.color.displayName} (Front)`;

  // check for rear plate before graving section _id
  if (activeEngravingContainerObj?.rear && activeEngravingContainerObj?.rear?.engraving.section !== "no_engraving")
    cartObj["options[design-rear-fill]"] = `${activeEngravingContainerObj.rear.color.displayName} (Rear)`;

  return cartObj;
};

// sets the key for image for front or rear
// NOTE: custom logo will be handled later in shopping cart
const handleEngravingImage = (componentsState, itemsState, engravingsIndex, cartObj) => {
  let activeEngravingContainerObj = itemsState.activeObjs[componentsState.array[engravingsIndex]._id];

  if (activeEngravingContainerObj.front?.engraving.section === "logo_engraving" && !activeEngravingContainerObj.front.engraving._id.includes("custom_logo"))
    cartObj["options[design-front-image]"] = `${activeEngravingContainerObj.front.engraving._id} (${
      activeEngravingContainerObj.front.engraving.svg_src?.includes("/approved/") ? "Mgp approved" : "Licensed"
    })`;

  if (activeEngravingContainerObj.rear?.engraving.section === "logo_engraving" && !activeEngravingContainerObj.rear.engraving._id.includes("custom_logo"))
    cartObj["options[design-rear-image]"] = `${activeEngravingContainerObj.rear.engraving._id} (${
      activeEngravingContainerObj.rear.engraving.svg_src?.includes("/approved/") ? "Mgp approved" : "Licensed"
    })`;

  return cartObj;
};

// sets the key for bolt colors if there are bolts
const handleBoltsColor = (itemsState, cartObj) => {
  if (itemsState.activeObjs.bolts_default.bolts._id === "bolts_on")
    cartObj["options[bolts-bolts-color]"] = `${itemsState.activeObjs.bolts_default.color.displayName} (Bolts)`;

  return cartObj;
};

// sets the design type key for front or rear only if mesh_editing_status is both
const handleDesignChoice = (componentsState, engravingsIndex, cartObj) => {
  if (componentsState.array[engravingsIndex].mesh_editing_status === "both") cartObj["options[design-choice]"] = "Use same engraving on front and rear covers";

  return cartObj;
};

// sets the key and custom text for front or rear
const handleCustomText = (componentsState, engravingsIndex, itemsState, cartObj) => {
  let activeEngravingContainerObj = itemsState.activeObjs[componentsState.array[engravingsIndex]._id];

  if (activeEngravingContainerObj.front?.engraving._id === "custom_text_front") {
    cartObj["options[design-front-text]"] = activeEngravingContainerObj.front?.engraving.text_input;
    cartObj["options[design-front-font]"] =
      activeEngravingContainerObj.front?.engraving.font_array[activeEngravingContainerObj.front?.engraving.active_font_index].displayName;
  }

  if (activeEngravingContainerObj.rear?.engraving._id === "custom_text_rear") {
    cartObj["options[design-rear-text]"] = activeEngravingContainerObj.rear?.engraving.text_input;
    cartObj["options[design-rear-font]"] =
      activeEngravingContainerObj.rear?.engraving.font_array[activeEngravingContainerObj.rear?.engraving.active_font_index].displayName;
  }

  return cartObj;
};
