import PropTypes from "prop-types";
import { animated, useSpring } from "react-spring";
import EmbedController from "../../../../embed/EmbedController";
import "./styles.scss";

export const RectangleButton = ({
  label,
  iconSrc = null,
  buttonType,
  buttonMode = "normal",
  isActive = false,
  positionStyle,
  onClickHandler,
  gaObj = null,
  customClass,
}) => {
  const [styles, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 }, to: { opacity: 1, scale: 1 } }));

  const handleClick = () => {
    onClickHandler();
    if (EmbedController.isEmbedded) EmbedController.sendGTMAnalyticsEvent(gaObj);
  };

  return (
    <animated.div style={styles}>
      <button
        onClick={handleClick}
        onTouchStart={(e) => {
          e.preventDefault();
          handleClick();
        }}
        className={`${buttonType} ${positionStyle} ${customClass} ${buttonMode === "containsActiveState" && !isActive && "disabled"} py-1 px-2 rectButton`}
      >
        {label}
        {iconSrc && buttonImageFactory(iconSrc, isActive, buttonMode)}
      </button>
    </animated.div>
  );
};

const buttonImageFactory = (iconSrc, isActive, buttonMode) => {
  switch (buttonMode) {
    case "normal":
      return iconSrc && <img alt="button image" src={iconSrc} />;
      break;

    case "containsActiveState":
      // display check mark
      return iconSrc && isActive && <img alt="button image" src={iconSrc} />;
    default:
      break;
  }
};

RectangleButton.propTypes = {
  label: PropTypes.string,
  iconSrc: PropTypes.string,
  buttonType: PropTypes.oneOf(["default", "secondary", "outline"]),
  buttonMode: PropTypes.oneOf(["normal", "containsActiveState"]),
  isActive: PropTypes.bool,
  onClickHandler: PropTypes.func,
  gaKey: PropTypes.string,
};
