import { useMemo } from "react";
import { ColorSection } from "../ColorSection/ColorSection";
import sift from "sift";
import { CustomColorSection } from "../ColorSection/CustomColorSection";
import { BottomNavigation } from "../BuildSection/BottomNavigation";
import { LitImageUpload } from "../FileDragDrop/LitImageUpload";

export const BaseColor = ({ componentsStateObj, itemsStateObj }) => {
  const colorItems = componentsStateObj.state.activeObj.items;

  const standardColors = useMemo(() => {
    return itemsStateObj.state.array.filter(sift({ _id: { $in: colorItems }, section: { $eq: "standard_baseColor" } })).sort((a, b) => {
      return componentsStateObj.state.activeObj.items.indexOf(a._id) - componentsStateObj.state.activeObj.items.indexOf(b._id);
    });
  }, []);
  const standardColorsSection = useMemo(() => {
    return componentsStateObj.state.activeObj.sections.find((colorSection) => colorSection._id === "standard_baseColor");
  }, []);
  const optionalColors = useMemo(() => {
    return itemsStateObj.state.array.filter(sift({ _id: { $in: colorItems }, section: { $eq: "optional_baseColor" } })).sort((a, b) => {
      return componentsStateObj.state.activeObj.items.indexOf(a._id) - componentsStateObj.state.activeObj.items.indexOf(b._id);
    });
  }, []);
  const optionalColorsSection = useMemo(() => {
    return componentsStateObj.state.activeObj.sections.find((colorSection) => colorSection._id === "optional_baseColor");
  }, []);
  const premiumColors = useMemo(() => {
    return itemsStateObj.state.array.filter(sift({ _id: { $in: colorItems }, section: { $eq: "premium_baseColor" } })).sort((a, b) => {
      return componentsStateObj.state.activeObj.items.indexOf(a._id) - componentsStateObj.state.activeObj.items.indexOf(b._id);
    });
  }, []);
  const premiumColorsSection = useMemo(() => {
    return componentsStateObj.state.activeObj.sections.find((colorSection) => colorSection._id === "premium_baseColor");
  }, []);

  const litSection = useMemo(() => {
    return componentsStateObj.state.activeObj.sections.find((colorSection) => colorSection._id === "lit_baseColor");
  }, []);

  const customColorsSection = useMemo(() => {
    return componentsStateObj.state.activeObj.sections.find((colorSection) => colorSection._id === "custom_baseColor");
  }, []);

  return (
    <div className="mt-3">
      <ColorSection
        colorData={standardColors}
        componentsState={componentsStateObj.state}
        title={standardColorsSection.displayName}
        additionalPrice={standardColorsSection.additional_price}
        itemsStateObj={itemsStateObj}
      />
      <ColorSection
        colorData={optionalColors}
        componentsState={componentsStateObj.state}
        title={optionalColorsSection.displayName}
        additionalPrice={optionalColorsSection.additional_price}
        itemsStateObj={itemsStateObj}
      />
      <ColorSection
        colorData={premiumColors}
        componentsState={componentsStateObj.state}
        title={premiumColorsSection.displayName}
        additionalPrice={premiumColorsSection.additional_price}
        itemsStateObj={itemsStateObj}
      />

      <CustomColorSection
        title={customColorsSection.displayName}
        componentsState={componentsStateObj.state}
        additionalPrice={componentsStateObj.state.activeObj.sections.find((colorSection) => colorSection._id === "custom_baseColor").additional_price}
        itemsStateObj={itemsStateObj}
      />
      <LitImageUpload
        title={litSection.displayName}
        componentsState={componentsStateObj.state}
        itemsStateObj={itemsStateObj}
        additionalPrice={litSection.additional_price}
      />

      <BottomNavigation componentsStateObj={componentsStateObj} />
    </div>
  );
};
