import { useAtom } from "jotai";
import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import { isMobile } from "react-device-detect";
import { usePrevious } from "../../../hooks/usePrevious";
import {
  components_state,
  is_driving,
  is_rim_section_active,
  items_state,
  mode_state,
  products_state,
  rim_material,
  update_components_activeId,
  update_items_activeIds,
} from "../../dataManagers/GlobalDataManagers";
import { BuildSection } from "../BuildSection/BuildSection";
import { HeadersBuildSection } from "../BuildSection/HeadersBuildSection";
import { Navigation } from "../BuildSection/Navigation";
import ControlsIndicator from "../ControlsIndicator/ControlsIndicator";
import Stepper from "../CustomStepper/CustomStepper";
import { HUD } from "../HUD/HUD";
import { NestedToggles } from "../NestedToggles/NestedToggles";
import { RectangleButton } from "../RectangleButton/RectangleButton";
import { RimSection } from "../RimSection/RimSection";
import { SceneLoadingScreen } from "../SceneLoadingScreen/SceneLoadingScreen";
import { SceneLoadingScreenTransparent } from "../SceneLoadingScreenTransparent/SceneLoadingScreenTransparent";
import { CreateScreens } from "../Screens/CreateScreens";
import { VideoGuide } from "../Widgets/VideoGuide";
import { WidgetCopyURL } from "../Widgets/WidgetCopyURL";
import { WidgetsContainer } from "../Widgets/WidgetsContainer";
import "./styles.scss";

export const BuilderUI = () => {
  // products
  const [productsState] = useAtom(products_state);

  // components
  const [componentsState] = useAtom(components_state);
  const [, setComponentsActiveId] = useAtom(update_components_activeId);
  const [, setComponentsState] = useAtom(components_state);

  // items
  const [itemsState] = useAtom(items_state);
  const [, setItemsActiveIds] = useAtom(update_items_activeIds);

  // drive mode
  const [modeState, setModeState] = useAtom(mode_state);
  const [isDriving, setIsDriving] = useAtom(is_driving);
  const [rimMaterial, setRimMaterial] = useAtom(rim_material);
  const [isRimSectionActive, setIsRimSectionActive] = useAtom(is_rim_section_active);

  const previousComponentActiveId = usePrevious(componentsState.activeId);

  useEffect(() => {
    if (modeState === "standalone" && isRimSectionActive) setIsRimSectionActive(false);
  }, [modeState]);

  const handleAddToCartClick = () => {
    setComponentsActiveId("shopping_cart");
    // give the shopping cart a chance to update before toggling rim section
    if (isRimSectionActive)
      setTimeout(() => {
        setIsRimSectionActive(false);
      }, 100);
  };

  return (
    <>
      <Container fluid className="BuilderUI p-0">
        {/* UI section on left (desktop) or bottom (mobile) of screen */}
        {productsState?.isPrimed && componentsState?.isPrimed && itemsState?.isPrimed && (
          <>
            <BuildSection>
              <HeadersBuildSection
                headerLabel={productsState.activeObj?.displayName}
                headerLineImgSrc="/images/mgpHeaderLine.svg"
                iconSrc="/images/iconInformationRed.png"
                productsState={productsState}
              />

              {componentsState.activeId !== "shopping_cart" && !isRimSectionActive && (
                <>
                  {/* must be a span as opposed to wrapping the components in a div for sticky navigation to work correctly */}
                  <span className="productTourTargetNavigateSteps"></span>
                  {/* <Navigation componentStateObj={{ state: componentsState, setState: setComponentsActiveId }} /> */}
                  {componentsState && componentsState.activeObj && (
                    <>
                      <Navigation state={componentsState} setState={setComponentsActiveId} />
                      <Stepper stepperData={componentsState} setStepper={setComponentsActiveId} />
                    </>
                  )}
                </>
              )}

              {isRimSectionActive ? (
                <RimSection
                  itemsState={itemsState}
                  rimSectionState={{ isRimSectionActive: isRimSectionActive, setIsRimSectionActive: setIsRimSectionActive }}
                  rimMaterialState={{ rimMaterial: rimMaterial, setRimMaterial: setRimMaterial }}
                />
              ) : (
                <CreateScreens
                  componentsStateObj={{ state: componentsState, setState: setComponentsState, setActiveId: setComponentsActiveId }}
                  productsState={productsState}
                  itemsStateObj={{ state: itemsState, setActiveId: setItemsActiveIds }}
                  previousComponentsState={previousComponentActiveId}
                />
              )}
            </BuildSection>
          </>
        )}

        {/* UI section overlaying the 3D scene */}
        {/* Uses .shared-scene-sizing to mimic the scene's canvas. */}
        <HUD>
          {/* loading screen used for the initial load - it covers the scene's canvas */}
          <SceneLoadingScreen componentsActiveId={componentsState.activeId} />
          {/* transparent loader for when assets are loading dynamically but we don't need to cover up whole screen */}
          <SceneLoadingScreenTransparent />

          {productsState.isPrimed && componentsState.isPrimed && itemsState.isPrimed && (
            <>
              {/* { !isMobile && <ProductTour componentStateObj={{ state: componentsState, setState: setComponentsActiveId }} />} */}

              {/* popup to inform the user of the scene controls */}
              <ControlsIndicator />

              <WidgetsContainer>
                <div className="float-left flex flex-col">
                  {/* add to cart btn */}
                  {(componentsState.activeId !== "shopping_cart" || isRimSectionActive) && (
                    <RectangleButton
                      label="Add To Cart"
                      buttonType="default"
                      positionStyle="top-0"
                      onClickHandler={handleAddToCartClick}
                      gaObj={{ event: "Add-to-Cart-MGP" }}
                    />
                  )}
                  <VideoGuide />
                </div>

                <div className="float-right d-inline-flex">
                  {!isMobile && <WidgetCopyURL />}
                  {/* controls for the wheels and rims */}
                  <NestedToggles
                    togglesArray={[
                      { title: "See Wheels", titleMobile: "Wheels", toggleState: modeState === "drive", setToggle: setModeState, isParent: true },
                      { title: "Spin Wheels", titleMobile: "Spin", toggleState: isDriving, setToggle: setIsDriving, isParent: false },
                      { title: "Rim Color", toggleState: isRimSectionActive, setToggle: setIsRimSectionActive, isParent: false },
                    ]}
                  />
                </div>
              </WidgetsContainer>
            </>
          )}
        </HUD>
      </Container>
    </>
  );
};
