import React, { useEffect, useMemo, useRef } from "react";
import Canvg from "canvg";
import SVGInject from "@iconfu/svg-inject";
import { useAtom } from "jotai";
import "./styles.scss";
import { items_state, loading_state } from "../../dataManagers/GlobalDataManagers";

// composites an svg, img, or text onto a canvas to be used as a texture in the scene
// 		injects the asset into the dom as an <img>
// 		CanvG used to convert to an inline svg and that svg gets composited onto the canvas
export function CanvasCompositor({ frontOrRear, isOnly1PlateActive, engravingComponent, engravingTextureAtom_front, engravingTextureAtom_rear }) {
  const [itemsState] = useAtom(items_state);
  const [, setLoadingState] = useAtom(loading_state);

  const isRearWaitingOnFront_ref = useRef(false);
  const [engravingTextureObj_front, setEngravingTextureObj_front] = useAtom(engravingTextureAtom_front);
  const [, setEngravingTextureObj_rear] = useAtom(engravingTextureAtom_rear);
  const setAssociatedEngravingTextureObj = (newVal) => {
    if (frontOrRear === "front") setEngravingTextureObj_front(newVal);
    else setEngravingTextureObj_rear(newVal);
  };

  const engravingItem = useMemo(() => {
    return itemsState.activeObjs[engravingComponent._id][frontOrRear].engraving;
  }, [engravingComponent, itemsState.activeObjs[engravingComponent._id][frontOrRear].engraving]);

  const colorItem = useMemo(() => {
    return itemsState.activeObjs[engravingComponent._id][frontOrRear].color;
  }, [engravingComponent, itemsState.activeObjs[engravingComponent._id][frontOrRear].color._id]);

  const frontItemObj = itemsState.activeObjs[engravingComponent._id].front;

  const canvasRef = React.useRef();
  const ctxRef = React.useRef();
  const canvgRef = React.useRef();
  const canvasSize = 512;
  const canvasPadding = 12;

  // svg's
  const svgSrc = engravingItem.svg_src;
  const engravingNormalMap = engravingItem.normalMap;
  // uploaded custom logo
  const uploadedImgSrc = engravingItem.uploaded_logo_src;
  const uploadedImgData = engravingItem.uploaded_logo_base64;
  // custom text
  const text = engravingItem.text_input;
  const fontIndex = engravingItem.active_font_index;
  const font = engravingItem.font_array?.[fontIndex];

  // anytime the src changes...
  useEffect(() => {
    handleNewData();
  }, [svgSrc, colorItem, uploadedImgSrc, uploadedImgData, text, font]);

  // whenever front is updated, trigger the rear to handleNewData
  // purpose is for rear to wait on front to load so rear can use the cached version
  useEffect(() => {
    if (frontOrRear === "rear" && engravingTextureObj_front && isRearWaitingOnFront_ref.current) {
      handleNewData();
      isRearWaitingOnFront_ref.current = false;
    }
  }, [engravingTextureObj_front]);

  // anytime the baseColor changes...update the background color on any special_engraving items
  const baseColor_ref = useRef(
    itemsState.activeObjs.baseColor_default.material_obj.svg_color || itemsState.activeObjs.baseColor_default.material_obj.constructor.color
  );
  useEffect(() => {
    baseColor_ref.current =
      itemsState.activeObjs.baseColor_default.material_obj.svg_color || itemsState.activeObjs.baseColor_default.material_obj.constructor.color;
    if (!engravingItem.special_engraving) return;
    let { isCached, imgEl } = checkSvgCache();
    if (isCached && imgEl) {
      changeSvgColor(null, imgEl);
      compositeSvgOnCanvas(null, imgEl);
    }
  }, [itemsState.activeObjs.baseColor_default._id]);

  /**
   *
   *
   * handle when there's new svg, img, or text data we need to react too
   *
   *
   */
  function handleNewData() {
    if (svgSrc) handleNewSvgData();
    else if (uploadedImgSrc) handleNewUploadedImageData();
    else if (text) handleNewTextData();
    else setAssociatedEngravingTextureObj("blank"); // reseting engravingTextureObj cause there's no engraving
  }

  function handleNewSvgData() {
    // if rear and front svg's are the same
    // && the user is changing both (need to make sure they are the same to handle case when experience is first loaded)
    // && the svg is NOT already cached
    // we want the rear svg to wait so it can used the cached version loaded by the front
    // unless there is only 1 plate active (rear)
    if (
      frontOrRear === "rear" &&
      isRearWaitingOnFront_ref.current === false &&
      engravingItem._id === frontItemObj?.engraving?._id &&
      engravingComponent.mesh_editing_status === "both" &&
      !checkSvgCache(true) &&
      !isOnly1PlateActive
    ) {
      isRearWaitingOnFront_ref.current = true;
    } else {
      // begin compositing process
      loadSvg();
    }
  }

  function handleNewUploadedImageData() {
    // check uploadedImgSrc to see if we should use uploadedImgData (base64) or uploadedImgSrc (url)
    let validImgSrc;
    if (uploadedImgSrc.slice(0, 4) === "temp") validImgSrc = uploadedImgData;
    else validImgSrc = uploadedImgSrc;

    // happens when experience is loaded with uploaded logo's that weren't saved
    if (!validImgSrc) {
      isRearWaitingOnFront_ref.current = false;
      setAssociatedEngravingTextureObj("blank"); // reseting engravingTextureObj cause there's no engraving
      return;
    }

    // if the user is changing both plate's image and image isn't already cached
    // we want the rear img to wait on front img to load
    if (
      frontOrRear === "rear" &&
      isRearWaitingOnFront_ref.current === false &&
      engravingComponent.mesh_editing_status === "both" &&
      !checkImgCache(true, validImgSrc) &&
      !isOnly1PlateActive
    ) {
      isRearWaitingOnFront_ref.current = true;
    } else {
      loadUploadedImage(validImgSrc);
    }
  }

  function handleNewTextData() {
    compositeTextOntoCanvas();
  }

  /**
   *
   *
   * load the new svg, img, or text data
   *
   *
   */

  function loadSvg() {
    let { isCached, imgEl } = checkSvgCache();

    if (isCached) {
      changeSvgColor(null, imgEl);
      compositeSvgOnCanvas(null, imgEl);
    } else {
      // show loading screen over scene
      setLoadingState(true);

      imgEl = document.createElement("img");
      imgEl.src = svgSrc;
      imgEl.setAttribute("data-custom-src", svgSrc);
      // imgEl.classList.add('svgPreview');

      imgEl.onload = SVGInject(imgEl, {
        useCache: false,
        makeIdsUnique: true,
        beforeInject: changeSvgColor,
        afterInject: compositeSvgOnCanvas,
      });

      document.getElementById("svgInject").appendChild(imgEl);
    }
  }

  function checkSvgCache(isRequestingBool) {
    let isCached = false,
      imgEl;
    let svgInject = document.getElementById("svgInject");

    // checking if we already have the svg loaded
    if (svgInject.lastChild) {
      svgInject.childNodes.forEach((svgEl) => {
        if (svgEl.nodeName?.toLowerCase() === "svg" && svgEl.getAttribute("data-custom-src") === svgSrc) {
          isCached = true;
          imgEl = svgEl;
        }
      });
    }

    if (isRequestingBool) {
      if (isCached) return true;
      else return false;
    } else {
      return { isCached, imgEl };
    }
  }

  async function loadUploadedImage(imgSrc) {
    let { isImgAlreadyLoaded, imgEl } = checkImgCache(false, imgSrc);

    if (isImgAlreadyLoaded) {
      compositeImgOnCanvas({ target: imgEl });
    } else {
      // show loading screen over scene (FYI doesn't have an effect when img is loaded as base64 aka user upload cause it's such a fast load)
      setLoadingState(true);

      // load image
      imgEl = document.createElement("img");
      imgEl.src = imgSrc;
      imgEl.crossOrigin = "anonymous";
      imgEl.onload = compositeImgOnCanvas;
      document.getElementById("imgInject").appendChild(imgEl);
    }
  }

  function checkImgCache(isRequestingBool, imgSrc) {
    let isImgAlreadyLoaded = false,
      imgEl;
    let imgInject = document.getElementById("imgInject");

    // checking if we already have the img loaded
    if (imgInject.lastChild) {
      imgInject.childNodes.forEach((el) => {
        if (el.getAttribute("src") === imgSrc && el.complete) {
          isImgAlreadyLoaded = true;
          imgEl = el;
        }
      });
    }

    if (isRequestingBool) {
      if (isImgAlreadyLoaded) return true;
      else return false;
    } else {
      return { isImgAlreadyLoaded, imgEl };
    }
  }

  /**
   *
   *
   * prepare and composite the data onto a canvas
   *
   *
   */

  function changeSvgColor(img, svg) {
    // handle fill color
    let svgEls = svg.querySelectorAll(".fill");
    svgEls.forEach((svgEl) => {
      svgEl.setAttribute("fill", colorItem.hex);
    });

    // handle stroke color
    svgEls = svg.querySelectorAll(".stroke");
    svgEls.forEach((svgEl) => {
      svgEl.setAttribute("stroke", colorItem.hex);
    });

    // handle caliper (background) color
    if (!engravingItem.special_engraving) return;
    svgEls = svg.querySelectorAll(".caliper");
    svgEls.forEach((svgEl) => {
      svgEl.setAttribute("fill", baseColor_ref.current);
      svgEl.setAttribute("stroke", baseColor_ref.current);
    });

    return;
  }

  async function compositeSvgOnCanvas(img, svg) {
    ctxRef.current = canvasRef.current.getContext("2d");

    let svgString = new XMLSerializer().serializeToString(svg);
    // makes 2px of padding
    let options = {
      scaleWidth: canvasSize - canvasPadding,
      scaleHeight: canvasSize - canvasPadding,
    };
    canvgRef.current = Canvg.fromString(ctxRef.current, svgString, options);
    await canvgRef.current.render();

    // let dynamicPngSrcResult = canvasRef.current.toDataURL("image/png");
    if (!svg.viewBox) return;
    let srcBox = svg.viewBox.baseVal;
    let srcImgAspectRatio = srcBox.width / srcBox.height;

    let newObj = {
      canvas: canvasRef.current,
      normalMap: engravingNormalMap,
      srcImgAspectRatio: srcImgAspectRatio,
    };

    setAssociatedEngravingTextureObj(newObj);
  }

  function compositeImgOnCanvas(loadEvt) {
    let image = loadEvt.target;

    ctxRef.current = canvasRef.current.getContext("2d");

    // draw image centered on canvas with correct aspect
    var hRatio = canvasRef.current.width / image.width;
    var vRatio = canvasRef.current.height / image.height;
    var ratio = Math.min(hRatio, vRatio);
    var centerShift_x = (canvasRef.current.width - image.width * ratio) / 2;
    var centerShift_y = (canvasRef.current.height - image.height * ratio) / 2;
    ctxRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    ctxRef.current.drawImage(image, 0, 0, image.width, image.height, centerShift_x, centerShift_y, image.width * ratio, image.height * ratio);

    let srcImgAspectRatio = image.width / image.height;

    // update engraving texture object for 3D scene
    let newObj = {
      canvas: canvasRef.current,
      normalMap: null,
      srcImgAspectRatio: srcImgAspectRatio,
    };

    setAssociatedEngravingTextureObj(newObj);
  }

  async function compositeTextOntoCanvas() {
    let ctx = ctxRef.current;
    let canvas = canvasRef.current;

    ctx = canvas.getContext("2d");

    let fontName = font.fontName;
    let fontSize = 100;
    let fontColor = colorItem.hex;
    let fontString = `${fontSize}px ${fontName}`;

    await document.fonts.load(fontString);

    ctx.fillStyle = fontColor;
    ctx.textAlign = "center";
    ctx.font = fontString;

    // calc the correct font size to fit on the canvas
    // 		start at 100px and if text protrudes outside canvas bounds, make it smaller
    let fontStats = ctx.measureText(text);
    if (fontStats.width > canvasSize - canvasPadding) {
      // too wide
      for (fontSize; fontSize < 101; fontSize--) {
        // update font with new size
        fontString = `${fontSize}px ${fontName}`;
        ctx.font = fontString;
        fontStats = ctx.measureText(text);
        // check if it fits in canvas bounds
        if (fontStats.width <= canvasSize - canvasPadding) break;
      }
    }

    let centeredHeight = canvas.height / 2 + (fontStats.actualBoundingBoxAscent - fontStats.actualBoundingBoxDescent) / 2;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.fillText(text, canvas.width / 2, centeredHeight);

    let srcImgAspectRatio = 100; // making this huge so texture is never scaled down
    // let srcImgAspectRatio = fontStats.width / (fontStats.actualBoundingBoxAscent + fontStats.actualBoundingBoxDescent);

    // update engraving texture object for 3D scene
    let newObj = {
      canvas: canvas,
      normalMap: null,
      srcImgAspectRatio: srcImgAspectRatio,
    };

    setAssociatedEngravingTextureObj(newObj);
  }

  return (
    <>
      {/* we only want 1 of these so we'll inject it on front */}
      {(frontOrRear === "front" || isOnly1PlateActive) && (
        <>
          <span id="svgInject"></span>
          <span id="imgInject"></span>
        </>
      )}

      <canvas id={`engravingCanvas_${frontOrRear}`} className="engravingCanvas" ref={canvasRef} width={canvasSize} height={canvasSize}></canvas>
    </>
  );
}
