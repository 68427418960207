import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { isMobile } from "react-device-detect";
import { RectangleButton } from "../RectangleButton/RectangleButton";

export const VideoGuide = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <RectangleButton
        label={isMobile ? "Video Guide" : "Customization Video Guide"}
        buttonType="secondary"
        positionStyle="mt-2"
        onClickHandler={() => setShowModal(true)}
      />

      <Modal className="videoGuideModal" show={showModal} size="md" centered style={{ zIndex: 1205 }} onHide={() => setShowModal(false)} keyboard={false}>
        <div className="iframe-container">
          <iframe
            className="responsive-iframe"
            src="https://www.youtube.com/embed/V6vd72xE5cU?si=cIH0qv7YKmWfVKfq"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullScreen
          ></iframe>
        </div>
      </Modal>
    </>
  );
};
