import { useMemo, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSpring, animated } from "react-spring";
import { useForm, useWatch } from "react-hook-form";
import "./styles.scss";

export const InputBox = ({ itemsStateObj, inputName, title, componentsState, additionalPrice = 0 }) => {
  const { register, handleSubmit, setValue, control } = useForm();

  const meshEditingStatus = componentsState.activeObj.mesh_editing_status;
  const [animationProps, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));

  // get custom_text items
  const customTextItemFront = useMemo(() => {
    return itemsStateObj.state.array.find((item) => item._id === "custom_text_front");
  }, [itemsStateObj.state.array]);

  const customTextItemRear = useMemo(() => {
    return itemsStateObj.state.array.find((item) => item._id === "custom_text_rear");
  }, [itemsStateObj.state.array]);

  // update the text input value
  useEffect(() => {
    updateInputValue();
  }, [meshEditingStatus, itemsStateObj.state]);

  // prevents animation from firing unless meshEditingStatus changes
  useEffect(() => {
    animate.start({ from: { opacity: 0, scale: 0 }, to: { opacity: 1, scale: 1 } });
  }, [meshEditingStatus]);

  function updateInputValue() {
    // set inputbox value to current engraving for front, rear, or both
    setValue(
      inputName,
      meshEditingStatus === "rear"
        ? itemsStateObj.state.activeObjs[componentsState.activeId].rear.engraving.text_input
        : itemsStateObj.state.activeObjs[componentsState.activeId].front.engraving.text_input
    );
  }

  const onSubmit = (data) => {
    const copy = { ...itemsStateObj.state.activeIds };

    let newActiveObjFront = {};
    let newActiveObjRear = {};

    newActiveObjFront._id = customTextItemFront._id;
    newActiveObjFront.inputs = customTextItemFront.inputs;

    newActiveObjRear._id = customTextItemRear._id;
    newActiveObjRear.inputs = customTextItemRear.inputs;

    if (meshEditingStatus === "both") {
      newActiveObjFront.inputs.text_input = data.customText;
      newActiveObjRear.inputs.text_input = data.customText;

      copy[componentsState.activeId].front.engraving = newActiveObjFront;
      copy[componentsState.activeId].rear.engraving = newActiveObjRear;
    } else if (meshEditingStatus === "front") {
      newActiveObjFront.inputs.text_input = data.customText;
      copy[componentsState.activeId].front.engraving = newActiveObjFront;
    } else {
      newActiveObjRear.inputs.text_input = data.customText;
      copy[componentsState.activeId].rear.engraving = newActiveObjRear;
    }

    itemsStateObj.setActiveId(copy);
  };

  return (
    <Row className="InputBox">
      <Col>
        <animated.div style={animationProps}>
          <form className="p-3" onSubmit={handleSubmit(onSubmit)} onBlur={handleSubmit(onSubmit)}>
            <div className="form-group">
              <div className="d-flex">
                <label htmlFor={inputName}> {title} </label>
                {additionalPrice != 0 && <h6 className="price ms-auto">{`$${additionalPrice}`}</h6>}
              </div>
              <div className="input-container">
                <input type="text" className="mb-3 form-control" name={inputName} {...register(inputName, { required: false, maxLength: 80 })} />
                <img src="/images/iconTextBox.svg" alt="Text Box" />
              </div>
            </div>

            <SubmitButton control={control} inputName={inputName} />
          </form>
        </animated.div>
      </Col>
    </Row>
  );
};

// isolate watch hook to prevent rerendering entire InputBox component
const SubmitButton = ({ control, inputName }) => {
  const inputData = useWatch({ control });

  return (
    <div className="d-flex justify-content-start">
      <button type="button" disabled={inputData[inputName]?.length === 0} className="btn btn-outline-danger btn-sm">
        Apply Text
      </button>
    </div>
  );
};
