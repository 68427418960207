import { useComponentsIndex } from "../../../hooks/useComponentsIndex";
import EmbedController from "../../../../embed/EmbedController";
import "./styles.scss";

export const Tabs = ({ tabLabels, componentsStateObj }) => {
  const { engravingsIndex } = useComponentsIndex(componentsStateObj.state);

  const handleClick = (tab) => {
    const copyComponentState = { ...componentsStateObj.state };
    copyComponentState.array[engravingsIndex].mesh_editing_status = tab.toLowerCase();

    componentsStateObj.setState(copyComponentState);

    if (EmbedController.isEmbedded) {
      EmbedController.sendGTMAnalyticsEvent({
        event: "Navigation Tab Click - MGP",
        tab: tab,
        page: componentsStateObj.state.activeId.split("_")[0],
      });
    }
  };

  const tabs = tabLabels.map((tab, index) => {
    const isActive = componentsStateObj.state.array[engravingsIndex].mesh_editing_status === tab.toLowerCase() ? "active" : "";
    return (
      <div key={tab} className={isActive}>
        <p className={`mb-0 py-1 tab text-center ${isActive}`} onClick={() => handleClick(tab)}>
          {tab}
        </p>
      </div>
    );
  });

  return <div className="TabsContainer d-flex mx-auto justify-content-around align-items-center">{tabs}</div>;
};
