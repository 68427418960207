import { useMemo } from "react";
import sift from "sift";
import { ColorSection } from "../ColorSection/ColorSection";

export const RimSection = ({ itemsState, rimSectionState, rimMaterialState }) => {
  const rimColorSection = useMemo(() => {
    return itemsState.array.filter(sift({ section: { $eq: "rim_colors" } }));
  }, []);

  return (
    <div className="mt-3">
      <img
        className="position-absolute backButton"
        role="button"
        src="/images/backButton.png"
        alt="Back"
        onClick={() => rimSectionState.setIsRimSectionActive(!rimSectionState.isRimSectionActive)}
      />
      <ColorSection
        title="Choose Rim Color:"
        isRimSectionActive={rimSectionState.isRimSectionActive}
        colorData={rimColorSection}
        rimMaterialState={rimMaterialState}
      />
    </div>
  );
};
