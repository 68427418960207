import { useMemo, useEffect } from "react";
import { Line } from "../Line/Line";
import { Boop } from "../Animations/Boop";
import { useSpring, animated } from "react-spring";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./styles.scss";

export const FontSection = ({ title, customTextData, componentsState, itemsStateObj }) => {
  const [animationProps, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));
  const meshEditingStatus = componentsState.activeObj.mesh_editing_status;

  // get custom_text item
  const customTextItemFront = useMemo(() => {
    return itemsStateObj.state.array.find((item) => item._id === "custom_text_front");
  }, [itemsStateObj.state.array]);

  const customTextItemRear = useMemo(() => {
    return itemsStateObj.state.array.find((item) => item._id === "custom_text_rear");
  }, [itemsStateObj.state.array]);

  useEffect(() => {
    animate.start({ from: { opacity: 0, scale: 0 }, to: { opacity: 1, scale: 1 } });
  }, [meshEditingStatus]);

  const handleClick = (index) => {
    const meshEditingStatus = componentsState.activeObj.mesh_editing_status;
    const copy = { ...itemsStateObj.state.activeIds };

    let newActiveObjFront = {};
    let newActiveObjRear = {};

    newActiveObjFront._id = customTextItemFront._id;
    newActiveObjFront.inputs = customTextItemFront.inputs;

    newActiveObjRear._id = customTextItemRear._id;
    newActiveObjRear.inputs = customTextItemRear.inputs;

    if (meshEditingStatus === "both") {
      newActiveObjFront.inputs.active_font_index = index;
      newActiveObjRear.inputs.active_font_index = index;

      copy[componentsState.activeId].front.engraving = newActiveObjFront;
      copy[componentsState.activeId].rear.engraving = newActiveObjRear;
    } else if (meshEditingStatus === "front") {
      newActiveObjFront.inputs.active_font_index = index;
      copy[componentsState.activeId].front.engraving = newActiveObjFront;
    } else {
      newActiveObjRear.inputs.active_font_index = index;
      copy[componentsState.activeId].rear.engraving = newActiveObjRear;
    }

    itemsStateObj.setActiveId(copy);
  };

  const fontItems = customTextData.font_array.map((font, index) => {
    const isFontActive = index === customTextData.active_font_index && true;

    return (
      <Col key={font.displayName} xs={4}>
        <Boop boopType="scale" scale={1.05} timing={200}>
          <div className={`fontContainer px-1 py-1 ${isFontActive ? "active" : "disabled"}`} onClick={() => handleClick(index)}>
            <img src={font.preview_img_src} alt="Font" />
            {/* { isFontActive && <img src="/images/checkmarkRed.svg" className="checkmark" alt="Check Mark" /> } */}
          </div>
        </Boop>
      </Col>
    );
  });

  return (
    <animated.div style={animationProps}>
      <div className="FontSection my-4">
        <div className="d-flex flex-column mb-2 text-center">
          {title && <h6 className="mb-1">{title}</h6>}
          {title && <Line color="black" height="1px" width="30px" />}
        </div>
        <Row className="fontItems text-center mt-3">{fontItems}</Row>
        <div className="mt-3">
          <Line color="#AFAFAF" height="1px" width="85%" />
        </div>
      </div>
    </animated.div>
  );
};
