import { useEffect, useRef, useMemo, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { isMobileOnly } from "react-device-detect";
import Switch from "@material-ui/core/Switch";
import { useSpring, animated } from "react-spring";
import { Line } from "../Line/Line";
import EmbedController from "../../../../embed/EmbedController";
import "./styles.scss";

export const NestedToggles = ({ togglesArray }) => {
  const ref = useRef(null);
  const [style, animate] = useSpring(() => ({ height: "0px" }), []);
  const parentToggle = useMemo(() => {
    return togglesArray.find((toggle) => toggle.isParent);
  }, [togglesArray]);
  const [isPrimed, setIsPrimed] = useState(false);

  useEffect(() => {
    document.addEventListener("IntroAnimationComplete", updateIsPrimed);
    return () => {
      document.removeEventListener("IntroAnimationComplete", updateIsPrimed);
    };
  }, []);

  const updateIsPrimed = () => {
    setIsPrimed(true);
  };

  useEffect(() => {
    if (!isPrimed) return;

    animate.start({
      height: (parentToggle.toggleState ? ref.current.offsetHeight : 38) + "px",
    });
  }, [togglesArray, isPrimed]);

  const onClickHandler = (toggle) => {
    if (toggle.isParent) toggle.setToggle(toggle.toggleState ? "standalone" : "drive");
    else toggle.setToggle(!toggle.toggleState);

    // prevents duplicate events from being pushed to GTM
    if (!toggle.toggleState && EmbedController.isEmbedded) {
      EmbedController.sendGTMAnalyticsEvent({
        event: "Drive Mode Click - MGP",
        action: toggle.title,
      });
    }
  };

  const toggles = togglesArray.map((toggle, i) => {
    if (isMobileOnly && !toggle.titleMobile) return null;
    return (
      <div key={toggle.title} style={{ cursor: "pointer" }} onClick={() => onClickHandler(toggle)}>
        <div className="d-flex align-items-center px-1 px-sm-2 py-0 pt1">
          <p className="m-0" style={{ color: "#f6f6f6" }}>
            {isMobileOnly ? toggle.titleMobile : toggle.title}
          </p>
          <RedSwitch
            className="ms-auto"
            checked={toggle.toggleState}
          // onChange is implemented on the parent btn ^
          />
        </div>
        {i !== togglesArray.length - 1 && !isMobileOnly && <Line color="#fafafa" height="1px" width="90%" />}
      </div>
    );
  });

  return (
    <>
      {isPrimed && (
        <animated.div style={style} className="togglesContainer d-inline-flex flex-column">
          <div ref={ref}>{toggles}</div>
        </animated.div>
      )}
    </>
  );
};

const RedSwitch = withStyles({
  switchBase: {
    color: "lightgrey",
    "&$checked": {
      color: "#fafafa",
    },
    "&$checked + $track": {
      backgroundColor: "#fafafa",
    },
  },
  checked: {},
  track: {},
})(Switch);
