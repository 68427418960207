/** Returns key/value as componentName: index
 * Used throughout the app to prevent bugs if the componentsState.array were to rearrange
 */

import { useMemo } from "react";

export const useComponentsIndex = (componentsState) => {
  const componentsIndexObj = useMemo(() => {
    return {
      baseColorDefaultIndex: componentsState.array.findIndex((component) => component._id === "baseColor_default"),
      boltsDefaultIndex: componentsState.array.findIndex((component) => component._id === "bolts_default"),
      engravingsIndex: componentsState.array.findIndex((component) => component._id.includes("engravings")),
    };
  }, [componentsState.array]);

  return componentsIndexObj;
};
