import { useAtom } from "jotai";
import { useEffect, useMemo, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useForm } from "react-hook-form";
import EmbedController from "../../../../embed/EmbedController";
import AlertSlackOfError from "../../../../monitoring/AlertSlackOfError";
import { useComponentsIndex } from "../../../hooks/useComponentsIndex";
import { useMgpCartKeys } from "../../../hooks/useMgpCartKeys";
import { usePrevious } from "../../../hooks/usePrevious";
import { delay } from "../../../modules/delay";
import { getFinalPrice } from "../../../modules/getFinalPrice";
import { getShoppingCartData } from "../../../modules/getShoppingCartData";
import { canvas_base64, session_id } from "../../dataManagers/GlobalDataManagers";
import { Boop } from "../Animations/Boop";
import { BottomNavigation } from "../BuildSection/BottomNavigation";
import { CardElementCart } from "../CardElement/CardElementCart";
import "./styles.scss";

const { v4: uuidv4 } = require("uuid");

export const ShoppingCart = ({ productsState, componentsStateObj, itemsStateObj, previousComponentsState }) => {
  const [show, setShow] = useState(false);
  const [showInvalidCartModal, setShowInvalidCartModal] = useState(false);
  const [isCartSubmissionPending, setIsCartSubmissionPending] = useState(false);
  const [isCartReadyForSubmission, setIsCartReadyForSubmission] = useState(false);
  const { handleSubmit, register, watch } = useForm();
  const componentsIndexObj = useComponentsIndex(componentsStateObj.state);
  const { baseColorDefaultIndex, engravingsIndex } = useComponentsIndex(componentsStateObj.state);
  const ttCartData = useMemo(
    () => getShoppingCartData(productsState, componentsStateObj.state, itemsStateObj.state, componentsIndexObj),
    [itemsStateObj.state]
  );
  const cartSubmissionObj = useMgpCartKeys(productsState, componentsStateObj.state, itemsStateObj.state, ttCartData);
  const finalPrice = getFinalPrice(productsState, ttCartData);
  const [sessionId] = useAtom(session_id);
  const previousSessionId = usePrevious(sessionId);

  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const handleBackClick = () =>
    componentsStateObj.setActiveId(
      previousComponentsState.includes("cart") ? componentsStateObj.state.array[baseColorDefaultIndex]._id : previousComponentsState
    );

  // setup callback for response once custom logo is saved on MGP site
  const litLogoPromise_ref = useRef();
  const frontLogoPromise_ref = useRef();
  const rearLogoPromise_ref = useRef();
  useEffect(() => {
    if (EmbedController.isEmbedded) {
      EmbedController.setLitLogoCallback((savedUrl) => {
        litLogoPromise_ref.current(savedUrl);
      });
      EmbedController.setUploadLogoCallback_front((savedUrl) => {
        frontLogoPromise_ref.current(savedUrl);
      });
      EmbedController.setUploadLogoCallback_rear((savedUrl) => {
        rearLogoPromise_ref.current(savedUrl);
      });
    }
  }, []);

  // setup callback for response when parent cart is updated
  useEffect(() => {
    if (EmbedController.isEmbedded) {
      EmbedController.setCartResponseCallback((errorMsg, redirectUrl) => {
        if (errorMsg || !redirectUrl) {
          console.log(`ERROR adding to shopping cart`);
          setIsCartSubmissionPending(false);
          setIsCartReadyForSubmission(false);
          setModalHeader("");
          setTimeout(setModalHeader("ERROR: please try again"), 500);
          AlertSlackOfError("ShoppingCart.jsx cart response", `error: ${errorMsg}`, sessionId);
          alert("Error submitting to cart. Please try again.");
        } else if (redirectUrl) {
          console.log(`cart redirectUrl in shopping cart: ${redirectUrl}`);
          handleCloseModal();
          setIsCartSubmissionPending(false);
          setIsCartReadyForSubmission(false);
          sessionId !== previousSessionId && sendTransactionAnalytics();
          EmbedController.sendParentUrlChange(redirectUrl);
        }
      });
    }
  }, []);

  /**
   *
   * send product data to MGP parent site's cart and redirect shopper there
   *
   */

  // runs when checkout btn is clicked
  const handleCheckout = async () => {
    setIsCartSubmissionPending(true);

    // SendMessageToSlack(
    //   `
    //   🎉 Product added to cart 🎉
    //   Price: ${finalPrice}
    //   Car: ${productsState.activeObj.displayName}
    //   Customized URL: <${EmbedController?.parentUrl || window.location.href}|here >
    //   Session Id: ${sessionId}
    //   isMobile: ${isMobile}
    //   `
    // )

    /**
     *
     * upload LIT texture, if applicable
     *
     */

    let litTextureSrc = getLitTextureData();
    if (litTextureSrc && EmbedController.isEmbedded) {
      let savedLitTextureUrl = await new Promise(async (resolve, reject) => {
        // check if custom logo's are already uploaded to media bucket
        if (litTextureSrc.slice(0, 5).includes("http")) resolve(litTextureSrc);
        else {
          // set the promise resolve function so we can call it when parent responds with saved logo
          litLogoPromise_ref.current = resolve;
          sendUploadLogoRequest(litTextureSrc, false, true);
        }
      });

      console.log("savedLitTextureUrl :>> ", savedLitTextureUrl);

      /**
       *
       * update itemsData (and url) to have the src of the stored texture
       *
       */
      let newItemIds = { ...itemsStateObj.state.activeIds };

      if (savedLitTextureUrl) newItemIds["baseColor_default"].inputs.uploaded_logo_src = savedLitTextureUrl;

      console.log("newItemIds :>> ", newItemIds);
      itemsStateObj.setActiveId(newItemIds);

      // delay before we update the `edit_url` (aka this configs custom link) that's sent to the cart
      await delay(1000);
      cartSubmissionObj["edit_url"] = EmbedController.parentUrl;
    }

    /**
     *
     * upload custom logo, if applicable
     *
     */

    let [logoObjsInCart, platesWithLogoSrc] = getCustomLogoData();

    // if there are custom logo's in the cart
    if (logoObjsInCart) {
      // upload logos to MGP media bucket
      let [savedLogoUrl_front, savedLogoUrl_rear] = await Promise.all([
        // front
        new Promise(async (resolve, reject) => {
          if (!platesWithLogoSrc.front) resolve(null);
          else if (EmbedController.isEmbedded) {
            // check if custom logo's are already uploaded to media bucket
            if (platesWithLogoSrc.front.slice(0, 5).includes("http")) resolve(platesWithLogoSrc.front);
            else {
              // set the promise resolve function so we can call it when parent responds with saved logo
              frontLogoPromise_ref.current = resolve;
              sendUploadLogoRequest(platesWithLogoSrc.front, true);
            }
          }
        }),

        // rear
        new Promise(async (resolve, reject) => {
          if (!platesWithLogoSrc.rear) resolve(null);
          else if (platesWithLogoSrc.rear === "frontCopy") resolve("frontCopy");
          else if (EmbedController.isEmbedded) {
            // check if custom logo's are already uploaded to media bucket
            if (platesWithLogoSrc.rear.slice(0, 5).includes("http")) resolve(platesWithLogoSrc.rear);
            else {
              // set the promise resolve function so we can call it when parent responds with saved logo
              rearLogoPromise_ref.current = resolve;
              sendUploadLogoRequest(platesWithLogoSrc.rear, false);
            }
          }
        }),
      ]);

      console.log("savedLogoUrl_front :>> ", savedLogoUrl_front);
      console.log("savedLogoUrl_rear :>> ", savedLogoUrl_rear);

      // handle when both plates use same logo
      if (savedLogoUrl_rear === "frontCopy") {
        savedLogoUrl_rear = savedLogoUrl_front;
      }

      /**
       *
       * update itemsData (and url) to have the src of the stored custom_logo
       *
       */
      let newItemIds = { ...itemsStateObj.state.activeIds };
      let engravingsComponentName = componentsStateObj.state.array[engravingsIndex]._id;

      if (savedLogoUrl_front) newItemIds[engravingsComponentName].front.engraving.inputs.uploaded_logo_src = savedLogoUrl_front;

      if (savedLogoUrl_rear) newItemIds[engravingsComponentName].rear.engraving.inputs.uploaded_logo_src = savedLogoUrl_rear;

      console.log("newItemIds :>> ", newItemIds);
      itemsStateObj.setActiveId(newItemIds);

      // delay before we update the `edit_url` (aka this configs custom link) that's sent to the cart
      await delay(1000);
      if (EmbedController) cartSubmissionObj["edit_url"] = EmbedController.parentUrl;
    }

    setIsCartReadyForSubmission(true);
  };

  const [custom_image_base64, setCustomImageBase64] = useAtom(canvas_base64);

  // we only submit to the cart once product screenshot has been taken and the cart is ready for submission
  useEffect(() => {
    if (custom_image_base64 && isCartReadyForSubmission) {
      submitToShoppingCart();
    }
  }, [custom_image_base64, isCartReadyForSubmission]);

  function submitToShoppingCart() {
    console.log("cartSubmissionObj :>> ", cartSubmissionObj);

    // update the custom image (screenshot of the caliper covers) to the cart obj so it's up-to-date
    // handles the case when the experience starts on the shopping cart
    cartSubmissionObj.custom_image = custom_image_base64 || "data:image/png;base64,iV";
    if (EmbedController.isEmbedded) {
      EmbedController.sendAddToCart(`/wp-admin/admin-ajax.php?action=configurator_addtocart`, cartSubmissionObj);
    }
  }

  function sendTransactionAnalytics() {
    EmbedController.sendGTMAnalyticsEvent({
      event: "purchase",
      ecommerce: {
        purchase: {
          actionField: {
            id: sessionId, // Required - random unique id
            affiliation: "Online Store",
            revenue: finalPrice,
            tax: 0,
            shipping: 0,
          },
          products: [
            {
              name: productsState.activeObj.displayName, // Name or ID is required.
              id: `${productsState.activeObj.part_number}CUSDF`,
              price: productsState.activeObj.base_price,
              quantity: 1,
            },
          ],
        },
      },
    });
  }

  // upload a custom logo to the MGP site's media bucket
  function sendUploadLogoRequest(uploadBase64, isFront, isLit) {
    let filename = `custom_logo_${uuidv4()}.png`;
    EmbedController.sendUploadLogo(
      `/wp-admin/admin-ajax.php?action=mgp_file_upload`,
      getRequestOptions({ upload: uploadBase64 }, false),
      filename,
      isFront,
      isLit
    );
  }

  function getLitTextureData() {
    let litTextureObj = ttCartData.find((cartObj) => cartObj._id == "lit_baseColor");
    if (litTextureObj?.uploadedLogoSrc?.includes("http")) return litTextureObj.uploadedLogoSrc;
    return litTextureObj?.uploadedLogoBase64;
  }

  function getCustomLogoData() {
    // build array of custom_logo's in the cart (none, front, rear, or both)
    let logoObjsInCart = ttCartData.filter((cartObj) => {
      if (cartObj._id?.includes("custom_logo")) return cartObj;
    });

    // only continue if there are custom logos in cart
    if (logoObjsInCart.length == 0) return [null];

    // will inform us which plates have a custom logo on it
    let platesWithLogoMap = { front: null, rear: null };

    // filter out duplicate logos
    logoObjsInCart.forEach((logoCartObj) => {
      if (logoCartObj._id.includes("front")) {
        platesWithLogoMap.front = logoCartObj.previewImg;
      } else if (logoCartObj._id.includes("rear")) {
        platesWithLogoMap.rear = logoCartObj.previewImg;
      }
    });

    // if rear logo is same as the front we'll edit the field saying they are duplicates
    if (platesWithLogoMap.front === platesWithLogoMap.rear) platesWithLogoMap.rear = "frontCopy";

    return [logoObjsInCart, platesWithLogoMap];
  }

  // helper to get the options obj for POST request (upload-logo or add-to-cart)
  function getRequestOptions(requestObj, isCartSubmission) {
    let requestOptions;

    // submit to cart
    if (isCartSubmission) {
      const encoded = new URLSearchParams(requestObj);
      requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encoded.toString(),
      };
    }

    // upload image
    else {
      // really want it to be form data but can't pass it to parent via postMessage()
      // so we just send the object and our tt_embed script takes care of converting the base64 logo to binary formData
      requestOptions = {
        method: "POST",
        body: JSON.stringify(requestObj),
        // content-type will be set in tt_embed
      };
    }

    return requestOptions;
  }

  const [modalHeader, setModalHeader] = useState("TERMS AND CONDITIONS");
  useEffect(() => {
    if (isCartSubmissionPending) setModalHeader("Enjoy Your Purchase!");
  }, [isCartSubmissionPending]);

  const acceptTermsModal = () => (
    <Modal
      className="terms-and-conditions-modal"
      show={show}
      size="md"
      style={{ zIndex: 1202 }}
      backdrop="static"
      onHide={() => handleCloseModal()}
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title className="mx-auto text-uppercase">{modalHeader}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Custom orders are made-to-order. Due to the nature of the product, we cannot accept cancellations, exchanges or returns. We strongly recommend you
          confirm fitment prior to placing your order. CUSTOM ORDERS CAN TAKE UP TO 15-25 BUSINESS DAYS TO MANUFACTURE BEFORE IT SHIPS.
        </p>
        {itemsStateObj.state.activeIds.baseColor_default._id === "carbon_fiber" && (
          <p className="production-time">"Carbon Fiber" production time is 15 business days</p>
        )}
        <form onSubmit={handleSubmit(handleCheckout)}>
          <label className="fw-bold">
            <input className="mx-1" name="acceptTerms" type="checkbox" {...register("acceptTerms", { required: true })} />I understand that my order cannot be
            canceled, exchanged, or returned
          </label>
          <br />
          <div className="d-flex justify-content-center mt-2">
            <button className="mx-2 px-2 submitBtn" disabled={!watch("acceptTerms") || isCartSubmissionPending} type="submit">
              I Agree
              {isCartSubmissionPending && <Spinner animation="border" style={{ width: "inherit", height: "inherit", verticalAlign: "0", marginLeft: "5px" }} />}
            </button>
            <button type="button" className="mx-2 px-2" style={{ backgroundColor: "grey" }} onClick={() => handleCloseModal()}>
              Go Back
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );

  const invalidCartModal = () => (
    <Modal show={showInvalidCartModal} size="md" style={{ zIndex: 1202 }} onHide={() => setShowInvalidCartModal(false)} keyboard={false}>
      <Modal.Header>
        <Modal.Title className="mx-auto text-uppercase">Invalid Cart</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          You selected a <strong>Custom Color</strong> but forgot to add the <strong>Paint Name</strong> or <strong>Paint Code</strong>.
        </p>
        <p>Please go back to the color step and include both fields or select a new color!</p>
        <div className="d-flex justify-content-center mt-2">
          <button
            className="mx-2 px-2"
            style={{ backgroundColor: "grey" }}
            onClick={() => componentsStateObj.setActiveId(componentsStateObj.state.array[baseColorDefaultIndex]._id)}
          >
            Take me
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );

  const handleAddToCartClick = () => {
    // check to confirm both paintName and paintCode were added before checkout
    if (
      itemsStateObj.state.activeIds.baseColor_default._id === "custom_baseColor" &&
      (!itemsStateObj.state.activeIds.baseColor_default?.inputs?.paintName || !itemsStateObj.state.activeIds.baseColor_default?.inputs?.paintCode)
    ) {
      setShowInvalidCartModal(true);
      return;
    }

    setIsCartReadyForSubmission(false);

    // signal to Scene.js to take a screenshot of caliper covers for the cart submission
    setCustomImageBase64(null);
    document.dispatchEvent(new CustomEvent("ScreenshotCanvasForCartImage"));

    handleShowModal();

    if (EmbedController.isEmbedded) {
      EmbedController.sendGTMAnalyticsEvent({
        event: "Add-to-Cart-BuildSection-MGP",
      });
    }
  };

  return (
    <>
      <div className="ShoppingCart my-3">
        <div className="d-flex align-items-center justify-content-around mb-1">
          <Boop boopType="scale" scale="1.1" timing={200}>
            <img
              className="backButton"
              onClick={handleBackClick}
              onTouchStart={(e) => {
                e.preventDefault();
                handleBackClick();
              }}
              src="/images/backButton.png"
              alt="back"
            />
          </Boop>
          <h4 className="mb-0 font-weight-bold">Purchase Summary</h4>
          <div style={{ width: "30px" }}></div>
        </div>
        <p className="text-center brand-color mb-3">{`*set of ${productsState.activeObj.set_count} caliper covers`}</p>
        <div className="d-flex mt-4 mb-3">
          <h5>{`Total: $${finalPrice}`}</h5>
          <button
            onClick={handleAddToCartClick}
            onTouchStart={(e) => {
              e.preventDefault();
              handleAddToCartClick();
            }}
            className="ms-auto checkoutBtn"
          >
            Add To Cart
          </button>
        </div>

        <CardElementCart cardContentArray={ttCartData} cardsPerRow={2} imgFrame={false} />
        <BottomNavigation handleAddToCartClick={handleAddToCartClick} componentsStateObj={componentsStateObj} />
      </div>

      <div className="Modal">
        {acceptTermsModal()}
        {invalidCartModal()}
      </div>
    </>
  );
};
