import React, { useState, useEffect } from "react";

// import { LinearProgress } from "@material-ui/core";

import "./styles.scss";

export const SceneLoadingScreen = ({ componentsActiveId }) => {
  const [isVisible, setIsVisible] = useState(true);
  const [itemLoadedCount, setItemLoadedCount] = useState(0);
  const [areEnvironmentAssetsLoaded, setAreEnvironmentAssetsLoaded] = useState(false);

  // we'll setup event listeners to know when all item's 3D assets have been loaded
  // then we'll make the loading screen invisible
  useEffect(() => {
    document.addEventListener("ItemAssetsLoaded", handleItemAssetsLoaded);
    document.addEventListener("EnvironmentAssetsLoaded", handleEnvironmentAssetsLoaded);
    return () => {
      document.removeEventListener("ItemAssetsLoaded", handleItemAssetsLoaded);
      document.removeEventListener("EnvironmentAssetsLoaded", handleEnvironmentAssetsLoaded);
    };
  }, []);

  // setup timer for loading screen
  // const [isMinTimerDone, _setIsMinTimerDone] = useState(true);
  // const isMinTimerDoneRef = useRef(true);
  // const setIsMinTimerDone = (newState) => {
  // 	isMinTimerDoneRef.current = newState;
  // 	_setIsMinTimerDone(newState);
  // }

  // Whenever a new scene is visited, we want to show the loading screen while it's assets are loading
  // useEffect(() => {
  // 	setIsVisible(true);
  // 	setItemLoadedCount(0);
  // 	// Show loading screen for a minimum of 1000ms so animations and such can occur properly
  // 	if (isMinTimerDoneRef.current) {
  // 		setIsMinTimerDone(false);
  // 		setTimeout(() => { setIsMinTimerDone(true) }, 1000);
  // 	}
  // }, [componentsActiveId])

  // checking if everything's loaded
  useEffect(() => {
    // 3 items are baseColor, engraving, and bolts assets
    if (itemLoadedCount >= 3 && areEnvironmentAssetsLoaded) {
      setTimeout(() => {
        if (isVisible) document.dispatchEvent(new CustomEvent("SceneIsBeingRevealed"));
        setIsVisible(false);
      }, 250);
    }
  }, [itemLoadedCount, areEnvironmentAssetsLoaded]);

  function handleItemAssetsLoaded(e) {
    setItemLoadedCount((itemLoadedCount) => itemLoadedCount + 1);
  }

  function handleEnvironmentAssetsLoaded() {
    setAreEnvironmentAssetsLoaded(true);
  }

  function handleImageLoad(event) {
    event.target.classList.add("loaded");
  }

  const [text, setText] = useState("Loading...");
  useEffect(() => {
    setTimeout(
      () =>
        setText(
          <>
            Please Refresh <br></br>
            Something Won't Load
          </>
        ),
      15000
    );
  }, []);

  return (
    <>
      {isVisible && (
        <div className="sceneLoadingScreen d-flex justify-content-center align-items-center">
          <div className="pl">
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="pl__dot"></div>
            <div className="content-container d-flex flex-column justify-content-center align-items-center">
              <div className="loadingLogoContainer">
                <img className="loadingLogo d-flex mx-auto" src="/images/loading-logo.png" onLoad={handleImageLoad} />
              </div>
              <div className="pl__text">{text}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
