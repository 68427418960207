import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTransition, animated } from "react-spring";
import PropTypes from "prop-types";
import "./styles.scss";

export const CardElementBolts = ({ cardContentArray, imgFrame = true, imgFrameColor = "#E9EDF0", cardsPerRow = 1, itemsStateObj, componentsState }) => {
  const layoutInfo = cardLayoutFactory(cardsPerRow);
  const itemsActiveBoltsId = itemsStateObj.state.activeIds[componentsState.activeId].bolts._id;

  // boltsId: bolts_on, bolts_off
  const handleClick = (boltsId) => {
    let copyItemsActiveIds = { ...itemsStateObj.state.activeIds };
    copyItemsActiveIds.bolts_default.bolts = { _id: boltsId };
    if (boltsId === "bolts_off") copyItemsActiveIds.bolts_default.color = { _id: "silver" }; // reset the color
    itemsStateObj.setActiveId(copyItemsActiveIds);
  };

  // returns an array with animation style data on each item
  const dataToDisplayTransitionArray = useTransition(cardContentArray, {
    trail: 100,
    from: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
  });

  return (
    <Row>
      {dataToDisplayTransitionArray((style, card, key, index) => (
        <Col xs={layoutInfo.xs} md={layoutInfo.md} key={card._id} className="mx-auto">
          <animated.div style={style}>
            <Card
              onClick={() => handleClick(card._id)}
              className={`cardElement mx-auto mx-2 mb-3 ${card._id !== itemsActiveBoltsId && "disabled"}`}
              style={{ width: layoutInfo.width, color: card?.customData?.cardColor ? "#fafafa" : "black" }}
            >
              <div className={`d-flex mx-auto mb-3 boltCard ${imgFrame && "imgFrame"}`} style={{ backgroundColor: imgFrame && imgFrameColor }}>
                <Card.Img alt="primary image" variant="top" className="mx-auto my-auto" src={card.image_url} />
              </div>
              <Card.Body className="py-2">
                <Card.Title className="text-center">{card.displayName}</Card.Title>
                {card?.description && <Card.Text>{card.description}</Card.Text>}
              </Card.Body>
            </Card>
          </animated.div>
        </Col>
      ))}
    </Row>
  );
};

const cardLayoutFactory = (cardPerRow) => {
  switch (cardPerRow) {
    case 1:
      return { xs: 8, md: 10, width: "16rem" };
    case 2:
      return { xs: 6, md: 6 };
    case 3:
      return { xs: 4, md: 4 };
    default:
      return { xs: 8, md: 10, width: "16rem" };
  }
};

CardElementBolts.propTypes = {
  /**
   * Contains card content and activeIndex
   */
  cardContentArray: PropTypes.array,
  /**
   * Whether you want white background behind the image
   */
  imgFrame: PropTypes.bool,
  /**
   * Color of the image background
   */
  imgFrameColor: PropTypes.string,
  /**
   * How many cards in a row in buildSection
   */
  cardsPerRow: PropTypes.number,
  componentsState: PropTypes.object,
  itemsStateObj: PropTypes.object,
};
