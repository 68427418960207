/**
 * The section where users can select a color.
 * Used for Standard, Optional, Premium, Text Color, and Bolt Color
 */
import { useMemo, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Col from "react-bootstrap/Col";
import PropTypes from "prop-types";
import { useSpring, useTransition, animated } from "react-spring";
import { Boop } from "../Animations/Boop";
import { isMobile } from "react-device-detect";
import { Line } from "../Line/Line";
import EmbedController from "../../../../embed/EmbedController";
import { ImgWithFallback } from "../ImgWithFallback/ImgWithFallback";
import "./styles.scss";

export const ColorSection = ({
  colorData,
  title,
  componentsState = null,
  itemsStateObj = null,
  additionalPrice = 0,
  columnsPerRow = 4,
  rimMaterialState = null,
  isRimSectionActive = false,
}) => {
  const meshEditingStatus = componentsState?.activeObj.mesh_editing_status;
  const componentStateActiveId = componentsState?.activeId;
  const [animationProps, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));
  const { displayName: activeColorLabel, _id: activeColorId } = isRimSectionActive
    ? colorData.find((color) => color._id === rimMaterialState.rimMaterial)
    : colorLabelFactory(colorData, itemsStateObj.state, componentStateActiveId, meshEditingStatus);

  useEffect(() => {
    animate.start({ from: { opacity: 0, scale: 0 }, to: { opacity: 1, scale: 1 } });
  }, [meshEditingStatus]);

  const handleClick = (color) => {
    if (EmbedController.isEmbedded) {
      EmbedController.sendGTMAnalyticsEvent({
        event: "Color Click - MGP",
        colorName: color.displayName,
        section: color.section,
        page: componentStateActiveId ? componentStateActiveId.split("_")[0] : "rim_section",
      });
    }

    if (isRimSectionActive) {
      rimMaterialState.setRimMaterial(color._id);
      return;
    }

    // updates itemsState activeIds
    let copy = { ...itemsStateObj.state.activeIds };
    if (componentStateActiveId === "baseColor_default") {
      copy.baseColor_default = { _id: color._id };
    } else if (componentStateActiveId === "bolts_default") {
      copy.bolts_default.color = { _id: color._id };
    } else {
      // engravings
      copy = updateEngravingColorFactory(meshEditingStatus, componentStateActiveId, copy, color);
    }

    itemsStateObj.setActiveId(copy);
  };

  // returns an array with animation style data on each item
  const animationOnItems = useTransition(colorData, {
    trail: 100,
    initial: { opacity: 0, scale: 0 },
    enter: { opacity: 1, scale: 1 },
    update: { enter: { opacity: 1, scale: 1 } },
    reset: true,
  });

  // prevents it from firing unless meshEditingStatus changes
  const dataToDisplayTransitionArray = useMemo(() => {
    return animationOnItems;
  }, [meshEditingStatus]);

  // color dots shown in UI
  const colorItems = dataToDisplayTransitionArray((style, color, key, index) => {
    return (
      <Col key={color._id} xs={columnsPerRow} className="position-relative">
        <animated.div style={style}>
          <Boop boopType="scale" scale={1.05} timing={200}>
            <OverlayTrigger
              key={color._id}
              placement="bottom"
              overlay={
                <Tooltip style={{ zIndex: isMobile ? -1 : 2 }} id={`tooltip-details-${color._id}`}>
                  <p className="m-0" >{color.displayName}</p>
                  {color.additionalInfo && <p className="m-0 production-time" >{color.additionalInfo}</p>}
                </Tooltip>
              }
            >
              {color?.image_url ? (
                <div>
                  <ImgWithFallback
                    src={color.image_url}
                    fallback={`${color.image_url.split(".")[0]}.png`}
                    alt={color.displayName}
                    className={`color3D ${color._id === activeColorId && "activeColor"}`}
                    onClick={() => handleClick(color)}
                  />
                </div>
              ) : (
                <div className={`d-flex align-items-center ${color._id === activeColorId && "activeColor"}`}>
                  <span
                    className={`colorDot m-1 ${color._id === "white" && "boxShadow"}`}
                    onClick={() => handleClick(color)}
                    style={{ backgroundColor: color.hex }}
                  ></span>
                </div>
              )}
            </OverlayTrigger>
          </Boop>
        </animated.div>
      </Col>
    );
  });

  return (
    <div className="ColorSection my-4">
      <animated.div style={animationProps}>
        <Row className="flex-column mb-2">
          {additionalPrice !== 0 && <h6 className="price ms-auto" style={{ position: "absolute", right: "25px", width: "auto" }}>{`$${additionalPrice}`}</h6>}
          {title && (
            <Col className="text-center">
              <h6 className="mb-1">{title}</h6>
              <Line color="black" height="1px" width="30px" />
            </Col>
          )}
          {activeColorLabel && (
            <Col className="activeColorLabel text-center">
              <h5 className="mt-2">{activeColorLabel}</h5>
            </Col>
          )}
        </Row>
      </animated.div>
      <Row className="colorItems text-center">{colorItems}</Row>
      <div className="mt-3">
        <Line color="#AFAFAF" height="1px" width="85%" />
      </div>
    </div>
  );
};

// returns the active color name depending on componentStateActiveId
const colorLabelFactory = (colorData, itemsState, componentsStateActiveId, meshEditingStatus) => {
  let activeColorLabel;
  const engravingsId = componentsStateActiveId.includes("engravings") ? componentsStateActiveId : "";

  switch (componentsStateActiveId) {
    case "baseColor_default":
      activeColorLabel = colorData.find((color) => color._id === itemsState.activeIds[componentsStateActiveId]._id) || {};
      break;
    case "bolts_default":
      activeColorLabel = colorData.find((color) => color._id === itemsState.activeIds[componentsStateActiveId].color._id) || {};
      break;
    case engravingsId:
      activeColorLabel =
        meshEditingStatus === "rear"
          ? colorData.find((color) => color._id === itemsState.activeIds[componentsStateActiveId].rear.color._id)
          : colorData.find((color) => color._id === itemsState.activeIds[componentsStateActiveId].front.color._id);
      break;

    default:
      break;
  }
  return activeColorLabel;
};

// updates the active color upon click
const updateEngravingColorFactory = (meshEditingStatus, componentsStateActiveId, copyItemStateActiveIds, color) => {
  switch (meshEditingStatus) {
    case "both":
      copyItemStateActiveIds[componentsStateActiveId].front.color = { _id: color._id };
      // match decal and color for rear cover to the front cover
      copyItemStateActiveIds[componentsStateActiveId].rear = {
        color: { _id: color._id },
        engraving: copyItemStateActiveIds[componentsStateActiveId].front.engraving,
      };
      break;
    case "front":
      copyItemStateActiveIds[componentsStateActiveId].front.color = { _id: color._id };
      break;
    case "rear":
      copyItemStateActiveIds[componentsStateActiveId].rear.color = { _id: color._id };
      break;

    default:
      break;
  }
  return copyItemStateActiveIds;
};

ColorSection.propTypes = {
  colorData: PropTypes.array.isRequired,
  title: PropTypes.string,
  itemsStateObj: PropTypes.object,
  componentsState: PropTypes.object,
  columnsPerRow: PropTypes.number,
  additionalPrice: PropTypes.number,
  isRimSectionActive: PropTypes.bool,
  rimMaterialState: PropTypes.object,
};
