import { useMemo } from "react";
import { CardElementBolts } from "../CardElement/CardElementBolts";
import sift from "sift";
import { BottomNavigation } from "../BuildSection/BottomNavigation";
import { ColorSection } from "../ColorSection/ColorSection";
import { useComponentsIndex } from "../../../hooks/useComponentsIndex";

export const Bolts = ({ componentsStateObj, itemsStateObj }) => {
  const { boltsDefaultIndex, engravingsIndex } = useComponentsIndex(componentsStateObj.state);
  const boltsItems = componentsStateObj.state.array[boltsDefaultIndex].items;
  const isBoltsOn = itemsStateObj.state.activeIds.bolts_default.bolts._id === "bolts_on";
  const boltCards = useMemo(() => {
    return itemsStateObj.state.array.filter(sift({ _id: { $in: boltsItems }, section: { $eq: "bolts" } }));
  }, []);
  const boltsColorSection = useMemo(() => {
    return itemsStateObj.state.array.filter(sift({ _id: { $in: boltsItems }, section: { $eq: "colors" } }));
  }, []);
  const boltsBasePrice = itemsStateObj.state.array.find((obj) => obj._id === "bolts_on").additional_price;

  /** Bolt base price is $10
   *  Additional $0 for Black or Silver
   *  Additional $0 if Engravings Color Section already has a color with a cost
   */
  const additionalPrice = useMemo(() => {
    return itemsStateObj.state.activeObjs[componentsStateObj.state.array[engravingsIndex]._id]["front"]?.color?.additional_price ||
      itemsStateObj.state.activeObjs[componentsStateObj.state.array[engravingsIndex]._id]["rear"]?.color?.additional_price
      ? boltsBasePrice
      : (itemsStateObj.state.activeObjs[componentsStateObj.state.array[boltsDefaultIndex]._id].color.additional_price || 0) + boltsBasePrice;
  }, [itemsStateObj.state.activeObjs[componentsStateObj.state.array[boltsDefaultIndex]._id].color]);

  if (itemsStateObj.state?.activeIds?.baseColor_default?._id === "lit_baseColor") {
    return <div className="text-center mt-4">Bolts are not compatible with Laser Imbedded Technology</div>;
  }

  return (
    <div className="mt-3">
      <CardElementBolts cardContentArray={boltCards} cardsPerRow={2} componentsState={componentsStateObj.state} itemsStateObj={itemsStateObj} />
      {isBoltsOn && (
        <ColorSection
          colorData={boltsColorSection}
          componentsState={componentsStateObj.state}
          title={componentsStateObj.state.array[boltsDefaultIndex].sections.find((section) => section._id === "bolts").displayName}
          itemsStateObj={itemsStateObj}
          additionalPrice={additionalPrice}
        />
      )}
      <BottomNavigation componentsStateObj={componentsStateObj} />
    </div>
  );
};
