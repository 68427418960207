import { useMemo, useState, useEffect } from "react";
import { Line } from "../Line/Line";
import { useForm } from "react-hook-form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { Boop } from "../Animations/Boop";
import ColorPicker from "./ColorPicker";
import "./styles.scss";

export const CustomColorSection = ({ title, itemsStateObj, componentsState, additionalPrice = 0 }) => {
  const [color, setColor] = useState(itemsStateObj.state.activeObjs.baseColor_default.material_obj.constructor.color);
  const [show, setShow] = useState(false);
  const [isFocus, setFocus] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { register, handleSubmit, watch, setValue } = useForm();

  // update the text input value
  useEffect(() => {
    if (itemsStateObj.state.activeObjs[componentsState.activeId]._id === "custom_baseColor") {
      updateInputValue();
    }
  }, [itemsStateObj.state]);

  const updateInputValue = () => {
    // set inputbox value to current paint name and code
    setValue("paintName", itemsStateObj.state.activeObjs[componentsState.activeId].inputs.paintName);
    setValue("paintCode", itemsStateObj.state.activeObjs[componentsState.activeId].inputs.paintCode);
  };

  // get custom_baseColor item
  const customBaseColorItem = useMemo(() => {
    return itemsStateObj.state.array.find((item) => item._id === "custom_baseColor");
  }, [itemsStateObj.state.array]);

  // make an abbreviated clone of current custom_baseColor item
  const getInputObj = () => {
    const { _id, inputs } = customBaseColorItem;
    return { _id, inputs };
  };

  const onSubmit = (data) => {
    let newActiveObj = getInputObj();

    // update its values with inputs from shopper
    newActiveObj.inputs.paintName = data.paintName;
    newActiveObj.inputs.paintCode = data.paintCode;

    // clone and update items_state.activeIds
    const copy = { ...itemsStateObj.state.activeIds, baseColor_default: newActiveObj };

    itemsStateObj.setActiveId(copy);
  };

  const handlePickerChangeComplete = (color) => {
    let newActiveObj = getInputObj();

    // update color with inputs from shopper
    newActiveObj.inputs.color = color.hex;

    const copy = { ...itemsStateObj.state.activeIds, baseColor_default: newActiveObj };

    itemsStateObj.setActiveId(copy);
    setColor(color.hex);
  };

  return (
    <div className="ColorSection mb-3">
      <Row className="flex-column text-center">
        {additionalPrice !== 0 && <h6 className="price ms-auto" style={{ position: "absolute", right: "25px", width: "auto" }}>{`$${additionalPrice}`}</h6>}
        {title && (
          <Col>
            <h6 className="mb-1">{title}</h6>
          </Col>
        )}
        {title && (
          <Col>
            <Line color="black" height="1px" width="30px" />
          </Col>
        )}
      </Row>

      <Row className="mb-2">
        <Col>
          <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="paintName">Enter Automotive Paint Name</label>
              <Boop boopType="scale" scale={1.1} timing={200}>
                <img className="questionBubble" onClick={() => handleShow()} src="/images/iconQuestionBubble.svg" alt="Question Bubble" />
              </Boop>
              <div className="input-container">
                <input
                  type="text"
                  className="mb-3 form-control"
                  name="paintName"
                  onFocus={() => setFocus(true)}
                  {...register("paintName", { required: false, maxLength: 80 })}
                />
                <img src="/images/iconPaintName.svg" alt="Paint Bucket" />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="paintCode">Enter Manufacturer Paint Code</label>
              <Boop boopType="scale" scale={1.1} timing={200}>
                <img className="questionBubble" onClick={() => handleShow()} src="/images/iconQuestionBubble.svg" alt="Question Bubble" />
              </Boop>
              <div className="input-container">
                <input
                  type="text"
                  className="mb-3 form-control"
                  name="paintCode"
                  onFocus={() => setFocus(true)}
                  {...register("paintCode", { required: false, maxLength: 80 })}
                />
                <img src="/images/iconPaintCode.svg" alt="Paint Bucket" />
              </div>
            </div>
            {isFocus && <p className="alertText mb-1">*Both name and code are required for custom color*</p>}
            <div className="d-flex justify-content-start">
              <button type="submit" disabled={!watch("paintName") || !watch("paintCode")} className="btn btn-outline-danger btn-sm">
                Save Paint
              </button>
            </div>
          </form>
        </Col>
      </Row>

      <Row className="flex-column px-3">
        <Col>
          <p className="mb-1">Color Picker for mock up only</p>
          <p className="alertText">*For preview only - color will not be used for actual covers*</p>
        </Col>
        <Col className="d-flex justify-content-center">
          <ColorPicker color={color} onChange={(color) => handlePickerChangeComplete(color)} />
        </Col>
        <div className="my-3">
          <Line color="#AFAFAF" height="1px" width="85%" />
        </div>
      </Row>

      <Modal show={show} size="md" style={{ zIndex: 1202 }} onHide={() => handleClose()} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>How To Find Your Paint Name And Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol>
            <li>
              Go to{" "}
              <a target="_blank" href="http://www.ppgpaintit.com/Collision.aspx?Brand=PPGNA&ReturnBrand=PPGNA">
                www.ppgpaintit.com
              </a>{" "}
              and enter the year and manufacturer of your vehicle.
            </li>
            <li>Select a color swatch. An info panel will appear with the color details.</li>
            <li>Locate the paint name at the top of the panel (highlighted in yellow).</li>
            <li>Manufacturer/ Paint Code is located in the center of the info panel (boxed in red).</li>
            <li>If there is more than 1 paint code listed, please use the first code.</li>
          </ol>
          <img src="/images/ppgpaintitScreenshot.svg" alt="PPGPaintIt" style={{ width: "100%" }} />
        </Modal.Body>
      </Modal>
    </div>
  );
};
