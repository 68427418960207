import axios from 'axios';
import AlertSlackOfError from "../monitoring/AlertSlackOfError";
import { decodeSynoptiveUrl } from "../react/modules/decodeSynoptiveUrl";

/**
 * This script handles logic for when the experience is embedded in an iframe in a client's site
 *
 * It is imported into our Root.js file and is subsequently used around the codebase (mainly in top-level data components)
 *
 * Messages will be passed between the parent site and embed iframe so our configurator can communicate with the client's site
 */

/**
 * variables
 */

let windowId = null;
let resizeTimer = null;
let hashChangeTimer = null;
let logoUploadTimer = null;
const hashChangeCallbacks = [];
const logoUploadCallbacks = { front: null, rear: null, lit: null };
let cartResponseCallback = null;
let localStorage = null;

/**
 *
 * EmbedController object that is exported to the rest of the codebase
 *
 * Used to send messages to the parent site
 *
 */

const EmbedController = {
  // used around the codebase to know if experience is embedded or not
  isEmbedded: false,

  // full parent URL for saving a config
  parentUrl: "",

  // activates the embed
  activate() {
    this.isEmbedded = true;
    setupMessageListeners();
  },

  /**
   *
   *
   * sending messages to parent
   *
   */

  // send request for hash change to the parent site
  sendHashChange(hash) {
    sendMessageToParent({
      method: "hashchange",
      hash,
    });
  },

  // send special hash change to parent site that signals we translated a synoptive hash to tt hash
  sendTranslatedHash(hash) {
    sendMessageToParent({
      method: "translatedHash",
      hash,
    });
  },

  // send request to change full URL of parent site (redirect)
  sendParentUrlChange(url) {
    sendMessageToParent({
      method: "parentUrlChange",
      url,
    });
  },

  // send request to reload the parent site
  sendParentReloadRequest() {
    sendMessageToParent({
      method: "parentReloadRequest",
    });
  },

  // send request to fully refresh the parent site
  sendParentRefreshRequest() {
    sendMessageToParent({
      method: "parentRefreshRequest",
    });
  },

  // send data to upload a custom logo
  sendUploadLogo(requestUrl, options, filename, isFront, isLit) {
    sendMessageToParent({
      method: "uploadLogo",
      requestUrl,
      options,
      filename,
      isFront,
      isLit,
    });
  },

  // send data to add to client site's cart
  sendAddToCart(requestUrl, options) {
    sendMessageToParent({
      method: "addToCart",
      requestUrl,
      options,
    });
  },

  // send localStorage data to client site
  sendLocalStorage(action, storageDetails) {
    sendMessageToParent({
      method: "localStorage",
      action, // set, get, remove
      storageDetails, // keyName and time to live data
    });
  },

  // send google analytics data to client site
  sendGTMAnalyticsEvent(data) {
    sendMessageToParent({
      method: "GTMAnalyticsEvent",
      dataLayerObj: data,
    });
  },

  /**
   *
   * callback's for responses from parent
   *
   */

  // listener that fires a cb when a hashchange on the parent occurs
  // we just store cb's in here and then process then in the event listener above
  // Called in UrlDataController.js
  setHashChangeCallback(callback) {
    hashChangeCallbacks.push(callback);
  },

  // upload logo responses
  setLitLogoCallback(callback) {
    logoUploadCallbacks.lit = callback;
  },
  setUploadLogoCallback_front(callback) {
    logoUploadCallbacks.front = callback;
  },
  setUploadLogoCallback_rear(callback) {
    logoUploadCallbacks.rear = callback;
  },

  // when product is added to cart
  setCartResponseCallback(callback) {
    cartResponseCallback = callback;
  },

  // local storage response
  setLocalStorageCallback(callback) {
    localStorage = callback;
  },
};

// if embedded in an iframe
// and not already activated
// activate
if (window.self !== window.top && !EmbedController.isEmbedded) EmbedController.activate();

function sendMessageToParent(message) {
  if (!EmbedController.isEmbedded) return;
  window.parent.postMessage(message, "*");
}

/**
 *
 * handle messages received from parent
 *
 */

function setupMessageListeners() {
  window.addEventListener("message", function (e) {
    const method = e.data.method;
    if (!method) {
      return;
    }

    if (messageHandlers[method]) {
      messageHandlers[method](e);
    }
  });
}

const messageHandlers = {
  register: handleRegisterMessageFromParent,

  hashchange: handleHashChangeMessage,

  "request-size": handleRequestSizeMessage,

  logoUploaded: handleUploadLogoResponse,

  cartResponse: handleCartResponseMessage,

  localStorage: handleLocalStorageMessage,
};

function updateParentUrlField(newUrl) {
  EmbedController.parentUrl = newUrl;
}

async function handleRegisterMessageFromParent(e) {
  // ------------- custom_code -------------
  // if there's an old synoptive config URL we'll translate it to our data structure
  let [isSynoptiveHash, ttHash] = await checkForSynoptiveHash(e.data.initialParentHash, e.data.fitmentData);

  if (isSynoptiveHash) {
    // send our translated hash
    EmbedController.sendTranslatedHash(ttHash);
    // return because this register function will be called again from parent
    return;
  }
  // ------------- custom_code -------------

  // window._tt will hold data from client site
  window._tt = e.data;
  windowId = e.data.windowId;

  // initialize parentUrl
  updateParentUrlField(e.data.initialParentUrl);

  // tell's Root.js to inject react code to start experience
  document.dispatchEvent(new CustomEvent("InitEmbed"));

  sendHeight();
  setTimeout(() => {
    sendHeight();
  }, 100);
  setTimeout(() => {
    sendHeight();
  }, 250);
}

// listener is set by onHashChange() above, which is called in UrlDataController.js
function handleHashChangeMessage(e) {
  updateParentUrlField(e.data.fullUrl);
  const hash = e.data.hash;
  clearTimeout(hashChangeTimer);
  hashChangeTimer = setTimeout(() => {
    hashChangeCallbacks.forEach((listener) => {
      listener(hash);
    });
  }, 100);
}

function handleUploadLogoResponse(e) {
  const { errorMsg, savedUrl, isFront, isLit } = e.data;
  if (errorMsg) {
    AlertSlackOfError("handleUploadLogoResponse in EmbedController", errorMsg);
    alert("Error with custom logo upload. Please refresh and try again.");
    return;
  }
  clearTimeout(logoUploadTimer);
  logoUploadTimer = setTimeout(() => {
    if (isFront) logoUploadCallbacks.front(savedUrl);
    else if (isLit) logoUploadCallbacks.lit(savedUrl);
    else logoUploadCallbacks.rear(savedUrl);
  }, 100);
}

function handleRequestSizeMessage(e) {
  clearTimeout(resizeTimer);
  resizeTimer = setTimeout(function () {
    sendHeight();
  }, 100);
}

function handleCartResponseMessage(e) {
  const { errorMsg, redirectUrl } = e.data;
  cartResponseCallback(errorMsg, redirectUrl);
}

// send's height to client site
function sendHeight() {
  if (windowId) {
    const body = document.body;
    const height = Math.max(body.scrollHeight, body.offsetHeight);
    sendMessageToParent({ method: "resize", windowId: windowId, height: height });
  }
}

function handleLocalStorageMessage(e) {
  const { foundItem } = e.data;

  if (foundItem) localStorage(foundItem);
}

// ------------- custom_code -------------

async function checkForSynoptiveHash(parentHash, fitmentData) {
  let isSynoptiveHash, ttHash;

  if (parentHash.slice(0, 10).includes(".()") || parentHash.slice(0, 10).includes(".%28%29"))
    // synoptive hashes start with .() OR the url encoded version of .() aka .%28%29

    isSynoptiveHash = true;
  else isSynoptiveHash = false;

  // convert synoptive data structure to our own
  if (isSynoptiveHash) {
    ttHash = await convertSynoptiveDataToTasteTest(parentHash, fitmentData);
  }

  return [isSynoptiveHash, ttHash];
}

// TODO: error handling
async function convertSynoptiveDataToTasteTest(synoptiveHash, fitmentData) {
  console.log("STARTING Hash CONVERSION on: ", synoptiveHash);

  /**
   * prepare synoptive's data for translation
   */
  let ttHash, synoptiveStringData, synoptiveArrayData;

  // convert synoptiveHash to text string of their config data
  synoptiveStringData = decodeSynoptiveUrl(synoptiveHash);

  // split data into array entries so we can work with them
  synoptiveArrayData = synoptiveStringData.split(",");
  console.log("synoptiveArrayData", synoptiveArrayData);

  // clean the data of '+' and ')' to normalize it
  synoptiveArrayData = synoptiveArrayData.map((stringVal) => {
    let newString = stringVal.replace("+", "");
    return newString.replace(")", "");
  });
  console.log("cleaned synoptiveArrayData", synoptiveArrayData);

  /**
   * begin creating the ttHash string
   */
  ttHash = "#";

  // attach the active product's _id to the hash (comes as part_number in the fitment data)
  ttHash = `${ttHash}/${fitmentData.part_number}`;

  // attach the active component's id (always baseColor_default)
  ttHash = `${ttHash}/baseColor_default`;

  console.log("ttHash", ttHash);

  /**
   * begin creating the activeId's obj that is attached to our url hash
   */
  let ttActiveIds = {};

  /**
   * baseColor
   */

  // handle a CUSTOM baseColor
  if (synoptiveArrayData.includes("col-pai-type-by_name")) {
    // identify the color
    let colorHex = synoptiveArrayData.find((entry) => {
      return entry.includes("col-pai-hex_input-hex_value[");
    });
    colorHex = colorHex.replace("col-pai-hex_input-hex_value[", "");
    colorHex = colorHex.replace("]", "");
    colorHex = decodeURIComponent(colorHex);

    // identify the paintCode
    let paintCode = synoptiveArrayData.find((entry) => {
      return entry.includes("col-pai-code_input-paint_code[");
    });
    paintCode = paintCode.replace("col-pai-code_input-paint_code[", "");
    paintCode = paintCode.replace("]", "");
    paintCode = decodeURIComponent(paintCode);

    // identify the paintName
    let paintName = synoptiveArrayData.find((entry) => {
      return entry.includes("col-pai-name_input-paint_name[");
    });
    paintName = paintName.replace("col-pai-name_input-paint_name[", "");
    paintName = paintName.replace("]", "");
    paintName = decodeURIComponent(paintName);

    // set the baseColor_default obj in the activeId's obj
    ttActiveIds["baseColor_default"] = {
      _id: "custom_baseColor",
      inputs: {
        color: colorHex,
        paintCode: paintCode,
        paintName: paintName,
      },
    };
  }

  // baseColor is one of the preset options
  else {
    // identify the synoptive baseColor data
    let baseColor_synoptive = synoptiveArrayData.find((entry) => {
      return entry.slice(0, 4) === "col-"; // always starts with col-
    });
    baseColor_synoptive = baseColor_synoptive.replace("col-", "");

    // fetch the baseColor_translation obj
    let baseColor_translation = await axios("/data/synoptiveTranslations/baseColor_translation.json");
    baseColor_translation = baseColor_translation.data;

    // translate the baseColor
    let baseColor_tt = baseColor_translation[baseColor_synoptive];

    // set the baseColor_default obj in the activeId's obj
    ttActiveIds["baseColor_default"] = { _id: baseColor_tt };
  }

  console.log("ttActiveIds", ttActiveIds);

  // used for the fill color of the bolts AND engravings
  let fillColor_translation;
  // fetch the fillColor_translation obj
  fillColor_translation = await axios("/data/synoptiveTranslations/fillColor_translation.json");
  fillColor_translation = fillColor_translation.data;

  /**
   * bolts
   */

  // setup the default bolts obj so it won't need to change if the bolts are inactive
  let boltsObj = {
    bolts: { _id: "bolts_off" },
    color: { _id: "silver" },
  };

  // identify the synoptive bolts data
  let boltsStatus_synoptive = synoptiveArrayData.find((entry) => {
    return entry.includes("bol-bolts-choice");
  });
  boltsStatus_synoptive = boltsStatus_synoptive.replace("bol-bolts-choice-", "");

  // translate the boltsStatus
  let boltsStatus_tt;
  if (boltsStatus_synoptive === "y") boltsStatus_tt = "bolts_on";
  // if (boltsStatus_synoptive === 'n')
  else boltsStatus_tt = "bolts_off";

  // translate the bolts color, IF BOLTS ARE ACTIVE
  if (boltsStatus_tt === "bolts_on") {
    // identify the synoptive bolt color data
    let boltsColor_synoptive = synoptiveArrayData.find((entry) => {
      return entry.includes("bol-bolts-color");
    });
    boltsColor_synoptive = boltsColor_synoptive.replace("bol-bolts-color-", "");

    // translate the boltColor
    let boltsColor_tt = fillColor_translation[boltsColor_synoptive];

    // update the boltsObj before it's added to our activeId's
    boltsObj.bolts._id = "bolts_on";
    boltsObj.color._id = boltsColor_tt;
  }

  // set the bolts_default obj in the activeId's obj
  ttActiveIds["bolts_default"] = boltsObj;

  console.log("ttActiveIds", ttActiveIds);

  /**
   *
   * engravings
   *
   */

  // start with front only since that's a given. We'll wait on rear to make sure there's a rear plate
  let engravingObj = {
    front: {
      engraving: { _id: "no_engraving" }, // default
      color: { _id: "silver" }, // default
    },
  };

  let font_translationObj; // might be used on front and rear so define var here

  /**
   * translate FRONT engraving data
   */

  // identify the synoptive engraving type
  let frontEngravingType_synoptive = synoptiveArrayData.find((entry) => {
    return entry.includes("des-fro-type");
  });
  frontEngravingType_synoptive = frontEngravingType_synoptive.replace("des-fro-type-", "");

  // handle each possible type...
  let frontEngravingData_synoptive;
  let frontEngravingColor_synoptive;

  // this means it could be an mgp_approved logo, licensed logo, or custom logo upload
  if (frontEngravingType_synoptive === "image") {
    frontEngravingData_synoptive = synoptiveArrayData.find((entry) => {
      return entry.includes("des-fro-ima");
    });
    frontEngravingData_synoptive = frontEngravingData_synoptive.replace("des-fro-ima-", "");

    // mgp_approved logo
    if (frontEngravingData_synoptive.includes("mgp_approved-")) {
      frontEngravingData_synoptive = frontEngravingData_synoptive.replace("mgp_approved-", "");
      engravingObj.front.engraving._id = frontEngravingData_synoptive;
    }

    // licensed logo
    else if (frontEngravingData_synoptive.includes("licensed-")) {
      frontEngravingData_synoptive = frontEngravingData_synoptive.replace("licensed-", "");
      engravingObj.front.engraving._id = frontEngravingData_synoptive;
    }

    // custom logo upload
    else if (frontEngravingData_synoptive.includes("custom-image")) {
      frontEngravingData_synoptive = frontEngravingData_synoptive.replace("custom-image[", "");
      frontEngravingData_synoptive = frontEngravingData_synoptive.replace("]", "");
      frontEngravingData_synoptive = decodeURIComponent(frontEngravingData_synoptive);
      engravingObj.front.engraving = {
        _id: "custom_logo_front",
        inputs: { uploaded_logo_src: frontEngravingData_synoptive },
      };
    }

    // set engraving color
    frontEngravingColor_synoptive = synoptiveArrayData.find((entry) => {
      return entry.includes("des-fro-fill");
    });
    frontEngravingColor_synoptive = frontEngravingColor_synoptive.replace("des-fro-fill-", "");

    let frontEngravingColor_tt = fillColor_translation[frontEngravingColor_synoptive];

    engravingObj.front.color._id = frontEngravingColor_tt;
  }

  // custom text engraving
  else if (frontEngravingType_synoptive === "text") {
    // get actual text input
    frontEngravingData_synoptive = synoptiveArrayData.find((entry) => {
      return entry.includes("des-fro-text");
    });
    frontEngravingData_synoptive = frontEngravingData_synoptive.replace("des-fro-text-input[", "");
    frontEngravingData_synoptive = frontEngravingData_synoptive.replace("]", "");
    frontEngravingData_synoptive = decodeURIComponent(frontEngravingData_synoptive);

    // get font
    let frontFont_synoptive = synoptiveArrayData.find((entry) => {
      return entry.includes("des-fro-font");
    });
    frontFont_synoptive = frontFont_synoptive.replace("des-fro-font-", "");

    // translate font
    font_translationObj = await axios("/data/synoptiveTranslations/font_translation.json");
    font_translationObj = font_translationObj.data;
    let font_index_tt_front = font_translationObj[frontFont_synoptive];

    // update engravingObj
    engravingObj.front.engraving = {
      _id: "custom_text_front",
      inputs: {
        text_input: frontEngravingData_synoptive,
        active_font_index: font_index_tt_front,
      },
    };

    // set engraving color
    frontEngravingColor_synoptive = synoptiveArrayData.find((entry) => {
      return entry.includes("des-fro-fill");
    });
    frontEngravingColor_synoptive = frontEngravingColor_synoptive.replace("des-fro-fill-", "");

    let frontEngravingColor_tt = fillColor_translation[frontEngravingColor_synoptive];

    engravingObj.front.color._id = frontEngravingColor_tt;
  }

  // no engraving
  else if (frontEngravingType_synoptive === "none") {
    // don't need to do anything because we set the default as 'no_engraving' (see up above ^^)
  }

  /**
   * translate REAR engraving data
   */

  // identify the synoptive engraving type
  let rearEngravingType_synoptive = synoptiveArrayData.find((entry) => {
    return entry.includes("des-rea-type");
  });

  // check to make sure there is a rear type. Some cars only have front plates
  if (rearEngravingType_synoptive) {
    // set default rear engraving obj
    engravingObj["rear"] = {
      engraving: { _id: "no_engraving" }, // default
      color: { _id: "silver" }, // default
    };

    rearEngravingType_synoptive = rearEngravingType_synoptive.replace("des-rea-type-", "");

    // handle each possible type...
    let rearEngravingData_synoptive;
    let rearEngravingColor_synoptive;

    // this means it could be an mgp_approved logo, licensed logo, or custom logo upload
    if (rearEngravingType_synoptive === "image") {
      rearEngravingData_synoptive = synoptiveArrayData.find((entry) => {
        return entry.includes("des-rea-ima");
      });
      rearEngravingData_synoptive = rearEngravingData_synoptive.replace("des-rea-ima-", "");

      // mgp_approved logo
      if (rearEngravingData_synoptive.includes("mgp_approved-")) {
        rearEngravingData_synoptive = rearEngravingData_synoptive.replace("mgp_approved-", "");
        engravingObj.rear.engraving._id = rearEngravingData_synoptive;
      }

      // licensed logo
      else if (rearEngravingData_synoptive.includes("licensed-")) {
        rearEngravingData_synoptive = rearEngravingData_synoptive.replace("licensed-", "");
        engravingObj.rear.engraving._id = rearEngravingData_synoptive;
      }

      // custom logo upload
      else if (rearEngravingData_synoptive.includes("custom-image")) {
        rearEngravingData_synoptive = rearEngravingData_synoptive.replace("custom-image[", "");
        rearEngravingData_synoptive = rearEngravingData_synoptive.replace("]", "");
        rearEngravingData_synoptive = decodeURIComponent(rearEngravingData_synoptive);
        engravingObj.rear.engraving = {
          _id: "custom_logo_rear",
          inputs: { uploaded_logo_src: rearEngravingData_synoptive },
        };
      }

      // set engraving color
      rearEngravingColor_synoptive = synoptiveArrayData.find((entry) => {
        return entry.includes("des-rea-fill");
      });
      rearEngravingColor_synoptive = rearEngravingColor_synoptive.replace("des-rea-fill-", "");

      let rearEngravingColor_tt = fillColor_translation[rearEngravingColor_synoptive];

      engravingObj.rear.color._id = rearEngravingColor_tt;
    }

    // custom text engraving
    else if (rearEngravingType_synoptive === "text") {
      // get actual text input
      rearEngravingData_synoptive = synoptiveArrayData.find((entry) => {
        return entry.includes("des-rea-text");
      });
      rearEngravingData_synoptive = rearEngravingData_synoptive.replace("des-rea-text-input[", "");
      rearEngravingData_synoptive = rearEngravingData_synoptive.replace("]", "");
      rearEngravingData_synoptive = decodeURIComponent(rearEngravingData_synoptive);

      // get font
      let rearFont_synoptive = synoptiveArrayData.find((entry) => {
        return entry.includes("des-rea-font");
      });
      rearFont_synoptive = rearFont_synoptive.replace("des-rea-font-", "");

      // translate font
      font_translationObj = await axios("/data/synoptiveTranslations/font_translation.json");
      font_translationObj = font_translationObj.data;
      let font_index_tt_rear = font_translationObj[rearFont_synoptive];

      // update engravingObj
      engravingObj.rear.engraving = {
        _id: "custom_text_rear",
        inputs: {
          text_input: rearEngravingData_synoptive,
          active_font_index: font_index_tt_rear,
        },
      };

      // set engraving color
      rearEngravingColor_synoptive = synoptiveArrayData.find((entry) => {
        return entry.includes("des-rea-fill");
      });
      rearEngravingColor_synoptive = rearEngravingColor_synoptive.replace("des-rea-fill-", "");

      let rearEngravingColor_tt = fillColor_translation[rearEngravingColor_synoptive];

      engravingObj.rear.color._id = rearEngravingColor_tt;
    }

    // no engraving
    else if (rearEngravingType_synoptive === "none") {
      // don't need to do anything because we set the default as 'no_engraving' (see up above ^^)
    }
  }

  /**
   * set engraving obj on ttActiveIds
   * BUT first, we must determine which engraving component will be used, which depends on the fitment's car "make"
   */

  // fetch makes.json
  let makesArray = await axios("/data/makes.json");
  makesArray = makesArray.data;

  // format the car "make" to match our component id's (undercase && spaces turn into -)
  let carMake = fitmentData.make.toLowerCase().replace(" ", "-");

  // derive the id of the tt engraving component using the makesArray
  let engravingComponentId = makesArray.includes(carMake) ? `engravings_${carMake}` : "engravings_default";

  // set the engraving component obj on our active id object
  ttActiveIds[engravingComponentId] = engravingObj;

  console.log("ttActiveIds", ttActiveIds);

  // append the encoded activeId's obj to the ttHash
  ttHash = `${ttHash}/${encodeURIComponent(JSON.stringify(ttActiveIds))}`;

  return ttHash;
}
// ------------- custom_code -------------

export default EmbedController;
