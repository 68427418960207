import { useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { CircleButton } from "../CircleButton/CircleButton";
import { Boop } from "../Animations/Boop";
import EmbedController from "../../../../embed/EmbedController";

export const WidgetCopyURL = () => {
  // content to show in overlay on btn click
  const tooltipContent = {
    onHover: "Copy caliper covers to clipboard",
    onClick: "Copied! Feel free to share or save this link",
    placement: "bottom",
  };

  // animations
  const [styles, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));

  const fireAnimation = () => {
    animate({ to: { opacity: 1, scale: 1 } });
  };

  useEffect(() => {
    document.addEventListener("IntroAnimationComplete", fireAnimation);
    return () => {
      document.removeEventListener("IntroAnimationComplete", fireAnimation);
    };
  }, []);

  const handleClick = () => {
    if (EmbedController.isEmbedded) navigator.clipboard.writeText(EmbedController.parentUrl);
    else navigator.clipboard.writeText(window.location.href);

    if (EmbedController.isEmbedded) {
      EmbedController.sendGTMAnalyticsEvent({
        event: "Copy Configuration Click - MGP",
      });
    }
  };

  return (
    <Boop rotation={20} timing={200}>
      <animated.div style={styles} onClick={handleClick} className="copyLinkButton mx-2">
        <CircleButton circleColor="#c71608" circleSize="37px" tooltipContent={tooltipContent} icon={"/images/iconCopyLink.svg"} />
      </animated.div>
    </Boop>
  );
};
