import React, { useState, useEffect } from "react";
import { Router } from "wouter";
import { useHashRoute, useHashLocation } from "./useHash";
import EmbedController from "../../../embed/EmbedController";
import { GlobalDataManagers, default_component_id } from "./GlobalDataManagers";
import AlertSlackOfError from "../../../monitoring/AlertSlackOfError";

/**
 *
 * This component manages reading and writing hash data in the URL, which defines the global state data
 * Reads active id's from URL hash and passes them down to the global data managers
 * Writes URL whenever global data managers call function to update an activeId, which was passed down to them
 *
 */

// URL params
// Ex. #/:productActiveId/:componentActiveId/:itemsActiveIds
const productParam = "productActiveId";
const componentParam = "componentActiveId";
const itemsParam = "itemsActiveIds";

// consumes the URL hash and creates data we need
const usePathParams = () => {
  const [hashLocation] = useHashLocation();
  const [productMatch, productParams] = useHashRoute(`#/:${productParam}`);
  const [componentMatch, componentParams] = useHashRoute(`#/:${productParam}/:${componentParam}`);
  const [itemsMatch, itemsParams] = useHashRoute(`#/:${productParam}/:${componentParam}/:${itemsParam}`);

  let paramsTemplate = {};

  if (EmbedController.isEmbedded) {
    // FYI default data is in GlobalDataManagers.js
    paramsTemplate[productParam] = window._tt?.fitmentData.part_number || undefined;
    paramsTemplate[componentParam] = default_component_id;
    paramsTemplate[itemsParam] = null;

    // edit params template according to current path
    if (!productMatch && !componentMatch && !itemsMatch) {
      return paramsTemplate;
    } else if (productMatch) {
      paramsTemplate[productParam] = window._tt?.fitmentData.part_number;
    } else if (componentMatch) {
      paramsTemplate[productParam] = window._tt?.fitmentData.part_number;
      if (
        componentParams[productParam] == window._tt?.fitmentData.part_number && // make sure productParam is correct. If not, don't update component param cause it'll be wrong
        !componentParams[componentParam].includes("%") &&
        !componentParams[componentParam].includes("{") // make sure component param isn't accidentally the item obj
      )
        paramsTemplate[componentParam] = componentParams[componentParam];
    } else if (itemsMatch) {
      paramsTemplate[productParam] = window._tt?.fitmentData.part_number;
      if (
        itemsParams[productParam] == window._tt?.fitmentData.part_number && // make sure productParam is correct. If not, don't update component param cause it'll be wrong
        !itemsParams[componentParam].includes("%") &&
        !itemsParams[componentParam].includes("{") // make sure component param isn't accidentally the item obj
      ) {
        paramsTemplate[componentParam] = itemsParams[componentParam];
        paramsTemplate[itemsParam] = uriToJSONString(itemsParams[itemsParam]);
      }
    }
  } else {
    // FYI default data is in GlobalDataManagers.js
    paramsTemplate[productParam] = null;
    paramsTemplate[componentParam] = default_component_id;
    paramsTemplate[itemsParam] = null;

    // edit params template according to current path
    if (!productMatch && !componentMatch && !itemsMatch) {
      return paramsTemplate;
    } else if (productMatch) {
      paramsTemplate[productParam] = productParams[productParam];
    } else if (componentMatch) {
      paramsTemplate[productParam] = componentParams[productParam];
      paramsTemplate[componentParam] = componentParams[componentParam];
    } else if (itemsMatch) {
      paramsTemplate[productParam] = itemsParams[productParam];
      paramsTemplate[componentParam] = itemsParams[componentParam];
      paramsTemplate[itemsParam] = uriToJSONString(itemsParams[itemsParam]);
    }
  }

  return paramsTemplate;
};

let hasSlackBeenAlerted = false;
// converts a URI to a json string or null if it's invalid json
function uriToJSONString(uri) {
  try {
    var stringUri = decodeURIComponent(uri);
    var objectUri = JSON.parse(stringUri);
    if (objectUri && typeof objectUri === "object") return stringUri;
  } catch (e) {
    if (!hasSlackBeenAlerted) {
      AlertSlackOfError("uriToJSONString() in UrlDataController.js", `Resetting invalid URI to null: ${uri}`);
      hasSlackBeenAlerted = true;
      alert("The URL you input was incorrectly formatted. To prevent an error, we've reset the configuration (URL)");
    }
  }
  return null;
}

export function UrlDataController({ children }) {
  // consume URL hash and get data we need from it
  const paramsObj = usePathParams();

  /**
   *
   * products
   *
   */

  // set initial state from URL hash
  const [products_activeId, set_products_activeId] = useState(paramsObj[productParam]);

  // when some child wants to update product active id,
  // update the URL hash with newId
  function update_products_activeId_inURL(newId) {
    updateURL(productParam, newId);
  }

  // anytime productParam in URL hash is updated,
  // we update the products active id
  useEffect(() => {
    set_products_activeId(paramsObj[productParam]);
  }, [paramsObj[productParam]]);

  /**
   *
   * components
   *
   */

  // set initial state from URL
  const [components_activeId, set_components_activeId] = useState(paramsObj[componentParam]);

  // when some child wants to update components active id,
  // update the URL hash with newId
  function update_components_activeId_inURL(newId) {
    updateURL(componentParam, newId);
  }

  // anytime componentParam in URL hash is updated,
  // we update the components active id
  useEffect(() => {
    set_components_activeId(paramsObj[componentParam]);

    // wait to fire event until all params are updated
    if (paramsObj[productParam] && paramsObj[componentParam] && paramsObj[itemsParam] && EmbedController.isEmbedded) {
      EmbedController.sendGTMAnalyticsEvent({
        event: "pageview",
        page: {
          path: `/virtualPageView/${paramsObj[componentParam].split("_")[0]}`,
          title: `Virtual Title - ${paramsObj[componentParam].split("_")[0]}`,
        },
      });
    }
  }, [paramsObj[componentParam]]);

  /**
   *
   * items
   *
   */

  // set initial state from URL
  const [items_activeIds, set_items_activeIds] = useState(JSON.parse(paramsObj[itemsParam]));

  // when some child wants to update items active ids,
  // update the URL hash with newIdObj
  function update_items_activeIds_inURL(newIdObj) {
    updateURL(itemsParam, newIdObj);
  }

  // anytime itemsParam in URL hash is updated,
  // we update the items active ids
  useEffect(() => {
    set_items_activeIds(JSON.parse(paramsObj[itemsParam]));
  }, [paramsObj[itemsParam]]);

  /**
   *
   *
   * Helper to update URL hash with new active id
   *
   *
   */

  function jsonToURI(json) {
    return encodeURIComponent(JSON.stringify(json));
  }

  const [hashLocation, setHashLocation] = useHashLocation();

  function updateURL(paramToUpdate, newValue) {
    let hashPath = "#/";

    // products
    if (window._tt?.fitmentData) {
      hashPath += `${window._tt?.fitmentData.part_number}/`;
    } else if (products_activeId || paramToUpdate === productParam) {
      hashPath += `${paramToUpdate === productParam ? newValue : products_activeId}/`;
    }

    // components
    if (components_activeId || paramToUpdate === componentParam) {
      hashPath += `${paramToUpdate === componentParam ? newValue : components_activeId}/`;
    }
    // handle bug when there's no components_activeId
    else if (items_activeIds || paramToUpdate === itemsParam) {
      hashPath += `${default_component_id}/`;
      AlertSlackOfError("updateURL() in UrlDataController.js", `blank component id. HashPath is manually set to ${hashPath}`);
    }

    // items
    if (items_activeIds || paramToUpdate === itemsParam) {
      // encoding obj to string
      hashPath += `${jsonToURI(paramToUpdate === itemsParam ? newValue : items_activeIds)}`;
    }

    /**
     *
     * update the URL
     *
     */
    if (hashLocation != hashPath) {
      // if embedded, update parent URL hash, which will reactivly update this embed URL hash (see code below)
      if (EmbedController.isEmbedded) EmbedController.sendHashChange(hashPath);
      else setHashLocation(hashPath);
    }
  }

  /**
   *
   *
   * If experience is embedded in parent site,
   * we send hash update requests to the parent
   * then react to hash updates from the parent by copying its hash here in the embed
   *
   */
  const [parentHashLocation, setParentHashLocation] = useState(window._tt?.initialParentHash || "#/");

  // setup listeners to react to parent site if we're embedded
  useEffect(() => {
    if (EmbedController.isEmbedded) {
      // setup callback for when parent hash is updated
      EmbedController.setHashChangeCallback((hash) => {
        setParentHashLocation(hash);
      });
    }
  }, []);

  // when parentHashLocation is updated, we update the embed's hash location, which controls the experience
  useEffect(() => {
    if (EmbedController.isEmbedded && parentHashLocation && parentHashLocation != hashLocation) {
      setHashLocation(parentHashLocation);
    }
  }, [parentHashLocation]);

  // when the browser back or forward btn's are used the hashLocation changes but parentHashLocation doesn't so we need to send the update to the parent
  // NOTE: I'm aware this is backwards compared to the rest of our URL logic (updates should be passed from parent to embed) but that would require us to handle the embedded experience and standalone's URL logic differently, which is less ideal
  useEffect(() => {
    if (EmbedController.isEmbedded) {
      // don't send duplicate updates (when embed hash and parent hash are equal) and don't update if experience is just starting (hashLocation is #/)
      if (hashLocation != parentHashLocation && hashLocation != "#/") {
        EmbedController.sendHashChange(hashLocation);
      }
    }
  }, [hashLocation]);

  return (
    <Router basePath={"/"} hook={useHashLocation}>
      <GlobalDataManagers
        // products
        products_activeId_fromURL={products_activeId}
        update_products_activeId_inURL={update_products_activeId_inURL}
        // components
        components_activeId_fromURL={components_activeId}
        update_components_activeId_inURL={update_components_activeId_inURL}
        // items
        items_activeIds_fromURL={items_activeIds}
        update_items_activeIds_inURL={update_items_activeIds_inURL}
      />

      {/* children is the ProductBuilder */}
      {children}
    </Router>
  );
}
