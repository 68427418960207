import reactCSS from "reactcss";
import merge from "lodash/merge";
import { ColorWrap, Saturation, Hue } from "react-color/lib/components/common";
import ChromePointer from "react-color/lib/components/chrome/ChromePointer.js";
import ChromePointerCircle from "react-color/lib/components/chrome/ChromePointerCircle.js";

export const ColorPicker = ({ onChange, rgb, hsl, hsv, hex, renderers, styles: passedStyles = {}, className = "", defaultView }) => {
  const styles = reactCSS(
    merge(
      {
        default: {
          picker: {
            width: "85%",
            background: "#fff",
            borderRadius: "2px",
            boxShadow: "0 0 2px rgba(0,0,0,.3), 0 4px 8px rgba(0,0,0,.3)",
            boxSizing: "initial",
          },
          saturation: {
            width: "100%",
            paddingBottom: "55%",
            position: "relative",
            borderRadius: "2px 2px 0 0",
            overflow: "hidden",
          },
          Saturation: {
            radius: "2px 2px 0 0",
          },
          body: {
            padding: "16px 16px 12px",
          },
          controls: {
            display: "flex",
          },
          toggles: {
            flex: "1",
          },
          hue: {
            height: "10px",
            position: "relative",
            marginBottom: "8px",
          },
          Hue: {
            radius: "2px",
          },
        },
      },
      passedStyles
    )
  );

  return (
    <div style={styles.picker} className={`chrome-picker ${className}`}>
      <div style={styles.saturation}>
        <Saturation style={styles.Saturation} hsl={hsl} hsv={hsv} pointer={ChromePointerCircle} onChange={onChange} />
      </div>
      <div style={styles.body}>
        <div style={styles.controls} className="flexbox-fix">
          <div style={styles.toggles}>
            <div style={styles.hue}>
              <Hue style={styles.Hue} hsl={hsl} pointer={ChromePointer} onChange={onChange} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColorWrap(ColorPicker);
