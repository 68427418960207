import _ from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FileDrop } from "react-file-drop";
import { animated, useSpring } from "react-spring";
import { Line } from "../Line/Line";
import "./styles.scss";

export const LitImageUpload = ({ title, componentsState, itemsStateObj, additionalPrice = 0 }) => {
  const [previewImg, setPreviewImg] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [animationProps, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));
  const fileInputRef = useRef(null);

  // get engravings component id
  const engravingComponentId = useMemo(() => {
    let engraving = componentsState.array?.find((component) => component._id.includes("engravings"));
    if (engraving) return engraving._id;
  }, [componentsState.array]);

  // get lit_baseColor item
  const litItem = useMemo(() => {
    return itemsStateObj.state.array.find((item) => item._id === "lit_baseColor");
  }, [itemsStateObj.state.array]);

  const isLitItemActive = useMemo(() => {
    return itemsStateObj.state.activeIds["baseColor_default"]._id === "lit_baseColor";
  }, [itemsStateObj.state.activeIds]);

  const { isPresetActive, activePresetId } = useMemo(() => {
    const isPresetActive = isLitItemActive && litItem.presets?.some((presetObj) => presetObj.src === litItem.uploaded_logo_src);
    const activePresetId = litItem.presets?.find((presetObj) => presetObj.src === litItem.uploaded_logo_src)?._id;
    return { isPresetActive, activePresetId };
  }, [isLitItemActive, litItem?.uploaded_logo_src]);

  // set preview image in drop zone
  useEffect(() => {
    if (isLitItemActive) {
      if (isPresetActive) {
        setPreviewImg(null);
      } else if (!_.isEmpty(litItem.uploaded_logo_src) && !litItem.uploaded_logo_src.includes("temp")) {
        setPreviewImg(litItem.uploaded_logo_src);
      } else if (!_.isEmpty(litItem.uploaded_logo_base64)) setPreviewImg(litItem.uploaded_logo_base64);
    } else {
      setPreviewImg(null);
    }
  }, [litItem, isLitItemActive, isPresetActive]);

  useEffect(() => {
    animate.start({ from: { opacity: 0, scale: 0 }, to: { opacity: 1, scale: 1 } });
  }, [litItem]);

  const handleOnUpload = (files, event) => {
    // file came from browse button
    if (event.currentTarget.className.includes("browseLocalFiles")) {
      files = event.target.files;
    }

    // only accept png and jpeg files
    const acceptedFileTypes = ["png", "jpeg"];
    if (!acceptedFileTypes.some((el) => files[0].type.includes(el))) {
      setErrorMessage("*Try uploading a PNG or JPEG*");
      return;
    } else {
      setErrorMessage(null);
    }

    // only accept images under this size
    if (files[0]?.size > 5000000) {
      setErrorMessage(`Image is too big. Must be less than 5 MB`);
      return;
    }

    let fileReader = new FileReader();
    // convert to base64
    fileReader.readAsDataURL(files[0]);

    fileReader.onload = () => {
      updateItems(fileReader.result);
      setPreviewImg(fileReader.result);
    };
  };

  const onTargetClick = () => {
    // mimic a click
    fileInputRef.current.click();
  };

  const onSlideChange = (_event, value) => {
    if (value === litItem.inputs.texture_scale) return;

    updateItemsState({
      _id: litItem._id,
      inputs: {
        ...litItem.inputs,
        texture_scale: value,
      },
    });
  };

  const updateItems = (processedImgFile) => {
    litItem.uploaded_logo_base64 = processedImgFile;
    litItem.material_obj.properties.map = processedImgFile;

    updateItemsState({
      _id: litItem._id,
      inputs: {
        ...litItem.inputs,
        uploaded_logo_src: `temp-${Math.random()}`,
      },
    });
  };

  function updateItemsState(newActiveIdObj) {
    const copy = { ...itemsStateObj.state.activeIds };
    copy[componentsState.activeId] = newActiveIdObj;

    // disable bolts
    copy["bolts_default"]["bolts"] = {
      _id: "bolts_off",
    };

    // disable engravings
    if (copy[engravingComponentId].front?.engraving) {
      copy[engravingComponentId].front.engraving = {
        _id: "no_engraving",
      };
    }
    if (copy[engravingComponentId].rear?.engraving) {
      copy[engravingComponentId].rear.engraving = {
        _id: "no_engraving",
      };
    }

    itemsStateObj.setActiveId(copy);
  }

  return (
    <animated.div style={animationProps}>
      <div className="d-flex flex-column mb-2">
        <div className="d-flex position-relative">
          {title && <h6 className="mx-auto mb-1">{title}</h6>}
          {additionalPrice != 0 && <h6 className="price position-absolute" style={{ right: "0px" }}>{`$${additionalPrice}`}</h6>}
        </div>
        {title && <Line color="black" height="1px" width="30px" />}
      </div>
      <div className="d-flex flex-column">
        <p className="text-secondary text-center" style={{ fontSize: "12px", fontStyle: "italic", padding: "0px 30px", margin: "0px" }}>
          A matte finish of any pattern texture you choose (w/ no logos)
        </p>
      </div>
      <Row>
        {litItem.presets?.map((presetObj) => (
          <Col xs={4} key={presetObj._id}>
            <div
              style={{
                height: "50px",
                maxWidth: "150px",
                overflow: "hidden",
                position: "relative",
                cursor: "pointer",
                borderRadius: "14px",
                margin: "auto",
                marginTop: "20px",
                boxShadow: "0px 0px 10px 1px darkgray",
              }}
              className={isPresetActive && activePresetId === presetObj._id ? "litPreset activePreset" : "litPreset"}
              onClick={() => {
                litItem.material_obj.properties.map = presetObj.src;
                updateItemsState({
                  _id: litItem._id,
                  inputs: {
                    ...litItem.inputs,
                    uploaded_logo_src: presetObj.src,
                  },
                });
              }}
            >
              <img
                src={presetObj.thumbnail}
                alt={presetObj.name}
                style={{ height: "auto", width: "100%", position: "absolute", top: "50%", transform: "translateY(-50%)" }}
              />
            </div>
          </Col>
        ))}
      </Row>
      {/* {isLitItemActive && ( */}
      {/*   <div className="d-flex flex-column" style={{ borderRadius: "14px", boxShadow: "0px 0px 10px 1px darkgray", marginTop: "20px" }}> */}
      {/*     <img src="/images/litExample.jpg" alt="Example of Lit Pattern" style={{ borderRadius: "14px 14px 0px 0px" }} /> */}
      {/*     <div className="d-flex justify-content-start align-items-center" style={{ padding: "5px 8px" }}> */}
      {/*       <img src="/images/iconInformationRed.png" alt="info icon" style={{ height: "21px" }} /> */}
      {/*       <p className="text-center" style={{ color: "black", fontSize: "12px", padding: "5px 15px", margin: "0px" }}> */}
      {/*         The back of your caliper cover will be white as shown here */}
      {/*       </p> */}
      {/*     </div> */}
      {/*   </div> */}
      {/* )} */}
      <div className="FileDragDrop p-3 my-4">
        <div className="dropContainer">
          <FileDrop onDrop={(files, event) => handleOnUpload(files, event)} onTargetClick={() => onTargetClick()}>
            <div className="d-flex flex-column">
              <p className="my-3 mx-auto text-center">Upload custom pattern:</p>
              <img
                className="mt-3 mx-auto"
                src={previewImg ? previewImg : "/images/dragDropPlaceHolderImg.svg"}
                alt="Place Holder"
                style={{ userSelect: "none", pointerEvents: "none" }}
              />
              {errorMessage && <p className="errorMessage mx-auto mt-4">{errorMessage} </p>}
              <p className="my-3 mx-auto text-center">
                Drag and drop or <span className="browseFilesButton">select from your files</span>
              </p>
              <input
                onChange={(event) => handleOnUpload(null, event)}
                onClick={(event) => {
                  event.target.value = null;
                }}
                ref={fileInputRef}
                accept="image/png,image/jpeg"
                style={{ display: "none" }}
                type="file"
                className="hidden browseLocalFiles"
              />
              <p className="mx-auto small">Supports JPG and PNG</p>
            </div>
          </FileDrop>
        </div>
      </div>
    </animated.div>
  );
};
