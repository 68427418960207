import * as THREE from "three";
import React, { Suspense, useState, useMemo, useEffect } from "react";
import { Canvas, useFrame, useThree, useLoader } from "@react-three/fiber";
import { PresentationControls, useAnimations } from "@react-three/drei";
import sift from "sift";
import { ResizeObserver } from "@juggle/resize-observer";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EnvironmentController from "./EnvironmentController";
// import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
// import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
// import { KTX2Loader } from "../../../scripts/PatchedKTX2Loader.js";
import { CanvasCompositor } from "./CanvasCompositor/CanvasCompositor";
// import { isMobile } from 'react-device-detect';
import PanCameraFromCursorControls from "./PanCameraFromCursorControls";
import OrbitControlsNoLookAt from "./OrbitControlsNoLookAt";
import AssetSystem3d, { useAssetLoader, useGLTFLoader } from "../dataManagers/AssetSystem3d";
import { delay } from "../../modules/delay";
import { useAtom } from "jotai";
import {
  products_state,
  items_state,
  engraving_texture_obj_front,
  engraving_texture_obj_rear,
  mode_state,
  is_driving,
  components_state,
  canvas_base64,
  loading_state,
  rim_material,
} from "../dataManagers/GlobalDataManagers";

export default function Scene() {
  const [productsState] = useAtom(products_state);
  const [componentsState] = useAtom(components_state);
  const [itemsState] = useAtom(items_state);

  const isMobile = useMediaQuery("(max-width: 767px)");

  const isRearPlateActive = productsState.activeObj?.plates.rear;
  const isFrontPlateActive = productsState.activeObj?.plates.front;
  const isOnly1PlateActive = isRearPlateActive && isFrontPlateActive ? false : true;

  const engravingComponent = useMemo(() => {
    return componentsState?.array?.find((component) => {
      return component._id.includes("engraving");
    });
  }, [componentsState.isPrimed, componentsState.array]);

  return (
    <>
      {/* not visible to user. handles svg, img, and text compositing onto canvas for engravings */}
      {itemsState.isPrimed && componentsState.isPrimed && productsState.isPrimed && (
        <>
          {itemsState.activeObjs[engravingComponent._id]?.front && (
            <CanvasCompositor
              frontOrRear={"front"}
              engravingComponent={engravingComponent}
              engravingTextureAtom_front={engraving_texture_obj_front}
              engravingTextureAtom_rear={engraving_texture_obj_rear}
              isOnly1PlateActive={isOnly1PlateActive}
            />
          )}
          {itemsState.activeObjs[engravingComponent._id]?.rear && (
            <CanvasCompositor
              frontOrRear={"rear"}
              engravingComponent={engravingComponent}
              engravingTextureAtom_front={engraving_texture_obj_front}
              engravingTextureAtom_rear={engraving_texture_obj_rear}
              isOnly1PlateActive={isOnly1PlateActive}
            />
          )}
        </>
      )}

      {/* canvas that will hold a screenshot of the main three.js canvas for the shopping cart */}
      <canvas id="screenshot_canvas" style={{ display: "none" }} width={1024} height={512}></canvas>

      {/* three scene's canvas */}
      <Canvas
        id="builder-scene-canvas-container"
        className="shared-scene-sizing builder-scene-canvas-container"
        camera={{
          position: [0, 0.5, 2.25],
          rotation: [0, 0, 0],
          fov: isMobile ? 48 : 45,
          near: 0.1,
          far: 20,
        }}
        gl={{ physicallyCorrectLights: true }}
        flat={true} // sets renderer.toneMapping = THREE.NoToneMapping
        dpr={window.devicePixelRatio}
        shadows={{ enabled: true, type: THREE.PCFShadowMap }}
        resize={{ polyfill: ResizeObserver }}
      >
        {/* camera controls */}
        <OrbitControlsNoLookAt
        // enableKeys={false}
        // enablePan={false}
        // enableRotate={false}
        // enableZoom={true}
        // minDistance={1}
        // maxDistance={3.5}
        />
        {!isMobile && <PanCameraFromCursorControls />}

        <AssetSystem3d>
          <Suspense fallback={null}>
            {/* environment assets, lighting, etc.  */}
            <EnvironmentController />

            {/* platform, wheels, caliper plates */}
            {productsState.isPrimed && (
              <ExperienceManager
                productsState={productsState}
                componentsState={componentsState}
                isRearPlateActive={isRearPlateActive}
                isFrontPlateActive={isFrontPlateActive}
                isOnly1PlateActive={isOnly1PlateActive}
              />
            )}
          </Suspense>
        </AssetSystem3d>
      </Canvas>
    </>
  );
}

// loads all models that are interactable and instantiates their controllers
// sets up shadows on models
// syncs mode animation for all models
function ExperienceManager({ productsState, componentsState, isRearPlateActive, isFrontPlateActive, isOnly1PlateActive }) {
  const { gl, scene, camera } = useThree();

  const [mode, setMode] = useAtom(mode_state);
  const [isDriving] = useAtom(is_driving);

  // shared as both plate's baseColor
  const [sharedBaseColorMat, setSharedBaseColorMat] = useState(null);

  // load platform, wheels, and plate models
  const [platformModel, frontPlateModel, rearPlateModel] = useLoader(useGLTFLoader, [
    "/mgp-caliper-assets/scenery/models/platform-with-wheels.glb",
    isFrontPlateActive ? productsState.activeObj.plates.front.model : "/mgp-caliper-assets/models/blank.glb",
    isRearPlateActive ? productsState.activeObj.plates.rear.model : "/mgp-caliper-assets/models/blank.glb",
  ]);

  const platformRotationGroup = platformModel.scene.children[0];
  const frontWheelAnimator = platformRotationGroup.getObjectByName("front-wheel-rotation-animator");
  const rearWheelAnimator = platformRotationGroup.getObjectByName("rear-wheel-rotation-animator");

  // whenever scene is revealed, trigger animation from drive > standalone mode
  // const isIntroAnimationActive_ref = useRef(false);
  // const cameraPosStart_ref = useRef(new THREE.Vector3());
  useEffect(() => {
    // primeIntroAnimation();
    document.addEventListener("SceneIsBeingRevealed", handleSceneReveal);
    return () => {
      document.removeEventListener("SceneIsBeingRevealed", handleSceneReveal);
    };
  }, []);

  function handleSceneReveal() {
    setTimeout(function () {
      setMode("standalone");
      document.dispatchEvent(new CustomEvent("IntroAnimationComplete"));
    }, 500);
  }
  // function primeIntroAnimation() {
  //   cameraPosStart_ref.current.set(camera.position.x, camera.position.y, camera.position.z)
  //   camera.position.set(1, 0.75, camera.position.z)
  // }
  // useFrame((state, delta) => {
  //   // lerp camera position to make intro animation occur
  //   if (isIntroAnimationActive_ref.current)
  //     camera.position.lerp(cameraPosStart_ref.current, 1.2 * delta)

  //   // when animation finishes
  //   if (isIntroAnimationActive_ref.current && camera.position.distanceTo(cameraPosStart_ref.current) <= 0.015) {
  //     isIntroAnimationActive_ref.current = false;
  //     setMode('standalone')
  //     document.dispatchEvent(new CustomEvent('IntroAnimationComplete'))
  //   }
  // })

  // passed into children controllers and used to coordinate animation between modes
  function modeTransitionAnimation(actions, actionName, mode) {
    if (!actions) return;

    let action = actions[actionName];
    action.clampWhenFinished = true;
    action.setLoop(THREE.LoopOnce);

    if (mode === "standalone") {
      action.paused = false;
      action.setEffectiveTimeScale(4);
      action.play();
    } else if (mode === "drive") {
      action.paused = false;
      action.setEffectiveTimeScale(-4);
      action.play();
    }
  }

  const driveSpeed = 2;
  useFrame((state, delta) => {
    if (isDriving) {
      frontWheelAnimator.rotation.z += delta * driveSpeed;
      if (isRearPlateActive) rearWheelAnimator.rotation.z += delta * driveSpeed;
    }
  });

  /**
   *
   * Convert the scene's canvas to a base64 image that can be sent to the client's shopping cart
   *
   */
  useEffect(() => {
    document.addEventListener("ScreenshotCanvasForCartImage", createProductScreenshot);
    return () => document.removeEventListener("ScreenshotCanvasForCartImage", createProductScreenshot);
  }, []);

  const [, setCanvasBase64] = useAtom(canvas_base64);
  const ourCamera_ref = React.useRef(new THREE.PerspectiveCamera(45, 2, 0.1, 10));

  async function createProductScreenshot() {
    // remove the wheels so the screenshot of caliper covers will be properly visible for MGP team
    setMode("standalone");
    await delay(1800);

    // using a different camera that we can control the view without the shopper noticing
    ourCamera_ref.current.position.set(0, 0.5, 1.25);

    // setting the size of the render canvas so it matches the size of the screenshot canvas.
    // 3rd param 'false' makes the canvas not change css sizes so shopper doesn't notice
    gl.setSize(1024, 512, false);

    // render the scene with our camera
    gl.render(scene, ourCamera_ref.current);

    // draw image centered on canvas with correct aspect
    let sceneCanvas = gl.domElement;
    let screenshotCanvas = document.getElementById("screenshot_canvas");
    let ctx = screenshotCanvas.getContext("2d");
    var hRatio = screenshotCanvas.width / sceneCanvas.width;
    var vRatio = screenshotCanvas.height / sceneCanvas.height;
    var ratio = Math.min(hRatio, vRatio);
    var centerShift_x = (screenshotCanvas.width - sceneCanvas.width * ratio) / 2;
    var centerShift_y = (screenshotCanvas.height - sceneCanvas.height * ratio) / 2;
    ctx.clearRect(0, 0, screenshotCanvas.width, screenshotCanvas.height);
    ctx.drawImage(
      sceneCanvas,
      0,
      0,
      sceneCanvas.width,
      sceneCanvas.height,
      centerShift_x,
      centerShift_y,
      sceneCanvas.width * ratio,
      sceneCanvas.height * ratio
    );

    // save as base64
    let base64 = screenshotCanvas.toDataURL("image/png");
    setCanvasBase64(base64);

    // reset the canvas size to the original size so resolution is correct
    let viewportEl = document.getElementById("builder-scene-canvas-container");
    gl.setSize(viewportEl.clientWidth, viewportEl.clientHeight, false);
  }

  const [itemsState] = useAtom(items_state);
  if (!itemsState.isPrimed) return null;

  return (
    <>
      {/* injects platform with road and wheels into scene + controls platform*/}
      <PlatformController platformModel={platformModel} driveSpeed={driveSpeed} />

      {/* wheel controllers */}
      <WheelController
        wheelMesh={platformRotationGroup.getObjectByName("front-wheel")}
        animations={platformModel.animations}
        mode={mode}
        modeTransitionAnimation={modeTransitionAnimation}
        frontOrRear={"front"}
      />
      <WheelController
        wheelMesh={platformRotationGroup.getObjectByName("rear-wheel")}
        animations={platformModel.animations}
        mode={mode}
        modeTransitionAnimation={modeTransitionAnimation}
        frontOrRear={"rear"}
      />

      {/* rim controllers */}
      <RimController
        rimMesh={platformRotationGroup.getObjectByName("front-rim")}
        animations={platformModel.animations}
        mode={mode}
        modeTransitionAnimation={modeTransitionAnimation}
        frontOrRear={"front"}
      />
      <RimController
        rimMesh={platformRotationGroup.getObjectByName("rear-rim")}
        animations={platformModel.animations}
        mode={mode}
        modeTransitionAnimation={modeTransitionAnimation}
        frontOrRear={"rear"}
      />

      {/* plate controllers */}
      {isFrontPlateActive && (
        <PlateController
          plateGltf={frontPlateModel}
          plateContainer={platformModel.scene.getObjectByName("front-wheel-plate-container")}
          animations={platformModel.animations}
          mode={mode}
          modeTransitionAnimation={modeTransitionAnimation}
          frontOrRear={"front"}
          sharedBaseColorMat={sharedBaseColorMat}
          setSharedBaseColorMat={setSharedBaseColorMat}
          isOnly1PlateActive={isOnly1PlateActive}
        />
      )}

      {isRearPlateActive && (
        <PlateController
          plateGltf={rearPlateModel}
          plateContainer={platformModel.scene.getObjectByName("rear-wheel-plate-container")}
          animations={platformModel.animations}
          mode={mode}
          modeTransitionAnimation={modeTransitionAnimation}
          frontOrRear={"rear"}
          sharedBaseColorMat={sharedBaseColorMat}
          setSharedBaseColorMat={setSharedBaseColorMat}
          isOnly1PlateActive={isOnly1PlateActive}
        />
      )}
    </>
  );
}

function PlatformController({ platformModel, driveSpeed }) {
  const [isDriving] = useAtom(is_driving);

  const roadMesh = useMemo(() => {
    let mesh;
    platformModel.scene.traverse((node) => {
      if (node.isMesh && node.material.name === "platform-road") {
        mesh = node;
      }
    });
    return mesh;
  }, [platformModel]);

  // setup shadows and set envMapIntensity
  useEffect(() => {
    roadMesh.receiveShadow = true;
    roadMesh.material.envMapIntensity = 0;
  }, []);

  // driving animation
  useFrame((state, delta) => {
    if (isDriving) roadMesh.material.map.offset.x -= delta * 0.28 * driveSpeed;
  });

  return (
    <PresentationControls
      global={true} // Spin globally or by dragging the model
      snap={true} // Snap-back to center (can also be a spring config)
      speed={1} // Speed factor
      zoom={1} // Zoom factor when half the polar-max is reached
      rotation={[0, 0, 0]} // Default rotation
      polar={[0, 0]} // Vertical limits
      azimuth={[-0.75, 0.75]} // Horizontal limits
      config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
    >
      <primitive object={platformModel.scene} />
    </PresentationControls>
  );
}

function WheelController({ wheelMesh, animations, mode, modeTransitionAnimation, frontOrRear }) {
  // setup shadows and set envMapIntensity
  useEffect(() => {
    wheelMesh.traverse((node) => {
      if (!node.isMesh) return;
      if (node.material.name === "black-backdrop" || node.material.name === "tire") {
        node.castShadow = true;
        node.material.envMapIntensity = 0.25;
      } else if (node.material.name === "brake-disk") {
        node.receiveShadow = true;
      }
    });
  }, []);

  // animation between drive and standalone mode
  const { actions } = useAnimations(animations, wheelMesh);
  useEffect(() => {
    modeTransitionAnimation(actions, "wheel-transition", mode);
  }, [mode]);

  return null;
}

function RimController({ rimMesh, animations, mode, modeTransitionAnimation, frontOrRear }) {
  const [itemsState] = useAtom(items_state);
  const [rimMaterialId] = useAtom(rim_material);

  const rimMaterial = useMemo(() => {
    return itemsState.array.filter(sift({ _id: rimMaterialId }))[0];
  }, [itemsState.array, rimMaterialId]);

  // setup shadows
  useEffect(() => {
    rimMesh.traverse((node) => {
      if (!node.isMesh) return;
      if (node.name.includes("rim")) {
        node.castShadow = true;
      }
    });
  }, []);

  // update rim material
  useEffect(() => {
    const { color, envMapIntensity, roughness, metalness } = rimMaterial.material_obj.constructor;
    rimMesh.traverse((node) => {
      if (node.isMesh && node.name.includes("rim")) {
        node.material.color.set(color);
        node.material.envMapIntensity = envMapIntensity;
        node.material.roughness = roughness;
        node.material.metalness = metalness;
      }
    });
  }, [rimMaterial]);

  const { actions } = useAnimations(animations, rimMesh);
  useEffect(() => {
    modeTransitionAnimation(actions, "rim-transition", mode);
  }, [mode]);

  return null;
}

// injects single plate model into scene
// instantiates controllers for the baseColor, engraving, and bolts mesh
// handles anything that applies to the whole plate group of mesh
function PlateController({
  plateGltf,
  plateContainer,
  animations,
  mode,
  modeTransitionAnimation,
  frontOrRear,
  sharedBaseColorMat,
  setSharedBaseColorMat,
  isOnly1PlateActive,
}) {
  // inject plates into their container inside wheels
  useEffect(() => {
    plateContainer.add(plateGltf.scene);
  }, []);

  // setup animations
  const { actions } = useAnimations(animations, plateContainer);
  useEffect(() => {
    modeTransitionAnimation(actions, `${frontOrRear}-plate-transition`, mode);
  }, [mode]);

  // toggle shadows on plates when mode changes
  useEffect(() => {
    toggleShadows(plateGltf.scene);
  }, [mode]);
  function toggleShadows(nodeToTraverse) {
    nodeToTraverse.traverse((node) => {
      if (node.isMesh) {
        // always keep casting shadow
        if (node.name.includes("baseColor")) node.castShadow = true;
        // only receive shadow in drive mode
        node.receiveShadow = mode === "drive" ? true : false;
      }
    });
  }

  // finds mesh for component controllers (baseColor, engraving, bolts)
  function findMesh(plateGltf, meshType) {
    let mesh;
    plateGltf.scene.traverse((node) => {
      if (node.name.includes(meshType) && node.isMesh) mesh = node;
    });
    return mesh;
  }

  return (
    <>
      <BaseColorController
        mesh={findMesh(plateGltf, "baseColor")}
        sharedBaseColorMat={sharedBaseColorMat}
        setSharedBaseColorMat={setSharedBaseColorMat}
        frontOrRear={frontOrRear}
        isOnly1PlateActive={isOnly1PlateActive}
      />
      <EngravingController mesh={findMesh(plateGltf, "engraving")} frontOrRear={frontOrRear} isOnly1PlateActive={isOnly1PlateActive} />
      <BoltsController mesh={findMesh(plateGltf, "bolts")} frontOrRear={frontOrRear} isOnly1PlateActive={isOnly1PlateActive} />
    </>
  );
}

// handles the baseColor component of the plates mesh
function BaseColorController({ mesh, sharedBaseColorMat, setSharedBaseColorMat, frontOrRear, isOnly1PlateActive }) {
  const [itemsState] = useAtom(items_state);
  const getAsset = useAssetLoader();

  const baseColorItem = itemsState.activeObjs.baseColor_default;
  const materialObj = baseColorItem.material_obj;
  const materialConstructor = materialObj.constructor;

  // updates sharedBaseColorMat via constructor whenever a new baseColor item is activated
  useEffect(() => {
    if (baseColorItem._id === "lit_baseColor" && !baseColorItem.material_obj.properties.map) {
      baseColorItem.material_obj.properties.map = baseColorItem.uploaded_logo_src;
    }
    if (frontOrRear === "front" || isOnly1PlateActive) updateSharedMaterial(); // only run on front plate since rear shares same mat
  }, [baseColorItem._id, baseColorItem.uploaded_logo_src]);
  async function updateSharedMaterial() {
    let newMat;
    if (materialObj.type === "MeshStandardMaterial") newMat = new THREE.MeshStandardMaterial(materialConstructor);
    else if (materialObj.type === "MeshPhysicalMaterial") newMat = new THREE.MeshPhysicalMaterial(materialConstructor);
    newMat.name = "sharedBaseColorMat";
    newMat = await applyMaterialProperties(getAsset, materialObj.properties, newMat);
    setSharedBaseColorMat(newMat);
  }

  useEffect(() => {
    if (sharedBaseColorMat && sharedBaseColorMat.map) {
      const scale = baseColorItem.texture_scale;
      if (scale) sharedBaseColorMat.map?.repeat.set(scale, scale);
    }
  }, [baseColorItem.texture_scale, sharedBaseColorMat?.map]);

  // whenever sharedBaseColorMat is updated, apply it to this plate's baseColor mesh
  useEffect(() => {
    if (!sharedBaseColorMat) return;
    let oldMat = mesh.material;
    mesh.material = sharedBaseColorMat;
    mesh.material.needsUpdate = true;
    oldMat.dispose();
    dispatchLoadedEvent();
  }, [sharedBaseColorMat]);

  // handles color change for custom_baseColor
  useEffect(() => {
    if (baseColorItem._id === "custom_baseColor" && (frontOrRear === "front" || isOnly1PlateActive)) mesh.material.color.set(materialConstructor.color);
  }, [baseColorItem._id, materialConstructor.color]);

  async function applyMaterialProperties(getAsset, material_props, material) {
    let texturePromises = [];

    Object.entries(material_props).forEach(([key, value]) => {
      // textures
      if (key.includes("map") || key.includes("Map")) {
        let texturePromise = new Promise(async (resolve) => {
          let texture = await getAsset(value);
          material[key] = texture;
          if (key.includes("metal")) material["roughnessMap"] = texture;
          else if (key.includes("rough")) material["metalnessMap"] = texture;
          resolve();
        });
        texturePromises.push(texturePromise);
      }

      // properties that need some preperation

      // arrays turn into vectors
      if (Array.isArray(value)) {
        material[key].fromArray(value);
      }
    });

    await Promise.all(texturePromises);

    material.needsUpdate = true;
    return material;
  }

  function dispatchLoadedEvent() {
    if (frontOrRear === "front" || isOnly1PlateActive) document.dispatchEvent(new CustomEvent("ItemAssetsLoaded"));
  }

  return false;
}

function BoltsController({ mesh, frontOrRear, isOnly1PlateActive }) {
  const [itemsState] = useAtom(items_state);
  const boltsColorObj = itemsState.activeObjs.bolts_default.color;
  const boltsState = itemsState.activeObjs.bolts_default.bolts._id;

  // patch fragment shader to clip at edges instead of repeat
  useEffect(() => {
    mesh.material.onBeforeCompile = (shader) => {
      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <map_fragment>",
        `
        #ifdef USE_MAP
          if (vUv.x > 1.0 || vUv.x < 0.0) discard;
          vec4 texelColor = texture2D( map, vUv );
          texelColor = mapTexelToLinear( texelColor );
          diffuseColor *= texelColor;
        #endif
        `
      );
    };
    dispatchLoadedEvent();
  }, [mesh]);

  // disable / enable bolts mesh
  useEffect(() => {
    if (boltsState === "bolts_on") mesh.visible = true;
    else mesh.visible = false;
  }, [boltsState]);

  // change bolts material
  useEffect(() => {
    mesh.material.color.set(boltsColorObj.hex);
  }, [boltsColorObj]);

  function dispatchLoadedEvent() {
    if (frontOrRear === "front" || isOnly1PlateActive) document.dispatchEvent(new CustomEvent("ItemAssetsLoaded"));
  }

  return null;
}

function EngravingController({ mesh, frontOrRear, isOnly1PlateActive }) {
  const [itemsState] = useAtom(items_state);
  const [productsState] = useAtom(products_state);
  const [, setLoadingState] = useAtom(loading_state);

  // get bolts state for engraving sizing algo
  const boltsState = itemsState.activeIds.bolts_default.bolts._id;

  // get correct engravingTextureObj depending upon front or rear plate
  const engraving_texture_obj_Atom = frontOrRear === "front" ? engraving_texture_obj_front : engraving_texture_obj_rear;
  const [engravingTextureObj] = useAtom(engraving_texture_obj_Atom);

  // patch the fragment shader to clip at edges instead of repeat
  useEffect(() => {
    mesh.material.onBeforeCompile = (shader) => {
      shader.fragmentShader = shader.fragmentShader.replace(
        "#include <map_fragment>",
        `
        #ifdef USE_MAP
          if (vUv.x > 1.0 || vUv.x < 0.0) discard;
          vec4 texelColor = texture2D( map, vUv );
          texelColor = mapTexelToLinear( texelColor );
          diffuseColor *= texelColor;
        #endif
        `
      );
    };
  }, [mesh]);

  // when boltsState is changed (bolts or no bolts)
  useEffect(() => {
    adjustEngravingForBolts();
  }, [boltsState]);

  // when engravingTextureObj is updated
  useEffect(() => {
    if (engravingTextureObj) handleNewEngraving();
  }, [engravingTextureObj]);

  const getAsset = useAssetLoader();

  async function handleNewEngraving() {
    if (engravingTextureObj === "blank") {
      // hide engraving
      mesh.visible = false;
    } else {
      /**
       * load textures
       */
      let asyncLoadingFunctions = [getAsset("canvas", engravingTextureObj.canvas)];
      if (engravingTextureObj.normalMap) asyncLoadingFunctions.push(getAsset(engravingTextureObj.normalMap));

      let [mapTexture, normalMapTexture] = await Promise.all(asyncLoadingFunctions);

      /**
       * create clones of texture to individually manipulate them
       */
      mapTexture = mapTexture.clone();
      if (normalMapTexture) normalMapTexture = normalMapTexture.clone();

      /**
       * dynamically size basColorTexture
       */
      mapTexture = sizeTextureForMesh(mesh, engravingTextureObj, mapTexture);

      /**
       * apply the engraving textures to mesh
       */
      applyNewEngraving(mapTexture, normalMapTexture);

      // make sure mesh is visible
      mesh.visible = true;
    }

    // makes loading screen triggered by CanvasCompositor disappear
    setLoadingState(false);
    // makes initial loading screen disappear
    dispatchLoadedEvent();
  }

  /**
   * resize texture depending upon the mesh's aspect ratio
   */
  function sizeTextureForMesh(mesh, textureDataObj, texture) {
    let uvAspectRatio = calcAspectRatio(mesh.geometry.boundingBox);
    let repeatXY;

    // no scaling needed. Logo will fit how it is
    if (textureDataObj.srcImgAspectRatio > uvAspectRatio) repeatXY = 1;
    // scale down so the y-axis stretches to UV bounds (while maintaining aspect ratio)
    else repeatXY = Math.min(uvAspectRatio, uvAspectRatio * (1 / textureDataObj.srcImgAspectRatio));

    texture.repeat.set(repeatXY, repeatXY);

    let offset = ((repeatXY - 1) / 2) * -1;
    texture.offset.x = offset;
    texture.offset.y = offset;

    return texture;
  }

  /**
   * resize and reposition mesh depending upon bolts state
   */
  function adjustEngravingForBolts() {
    const boltsAreActive = boltsState === "bolts_on" ? true : false;

    if (boltsAreActive) {
      mesh.scale.set(1, 1, 1);
      mesh.position.set(mesh.position.x, 0, mesh.position.z);
    } else {
      let { scale_bolts_off, posY_bolts_off } = productsState.activeObj.plates[frontOrRear];
      mesh.scale.set(scale_bolts_off, scale_bolts_off, 1);
      mesh.position.set(mesh.position.x, posY_bolts_off, mesh.position.z);
    }
  }

  function applyNewEngraving(map, normalMap) {
    adjustEngravingForBolts();

    /**
     * apply textures to material
     */
    let oldMap = mesh.material.map;
    let oldNormalMap = mesh.material.normalMap;
    mesh.material.map = map;
    if (normalMap) mesh.material.normalMap = normalMap;
    else mesh.material.normalMap = null;

    /**
     * mark textures and mat for update
     */
    map.needsUpdate = true;
    if (normalMap) normalMap.needsUpdate = true;
    mesh.material.needsUpdate = true;

    if (oldMap) oldMap.dispose();
    if (oldNormalMap) oldNormalMap.dispose();
  }

  function dispatchLoadedEvent() {
    if (frontOrRear === "front" || isOnly1PlateActive) document.dispatchEvent(new CustomEvent("ItemAssetsLoaded"));
  }

  function calcAspectRatio(boundingBox) {
    // width / height
    return (boundingBox.max.x - boundingBox.min.x) / (boundingBox.max.y - boundingBox.min.y);
  }

  return null;
}
