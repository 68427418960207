import * as THREE from "three";
import { useRef, useEffect, useMemo, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";

export default function PanCameraFromCursorControls() {
  const camera = useThree(({ camera }) => camera);
  const sceneEl = useMemo(() => {
    return document.getElementById("builder-scene-canvas-container");
  }, []);
  const isActive_ref = useRef(false);
  const [isIntroAnimationComplete, setIsIntroAnimationComplete] = useState(false);

  const startPosVec_ref = useRef(new THREE.Vector3(0, 0.5, 2.25));
  const targetPosVec_ref = useRef(startPosVec_ref.current.clone());
  const lookAtPos_ref = useRef(new THREE.Vector3(0, 0.45, 0));
  const speed_ref = useRef(0.5);
  const variance_x = 0.5;
  const variance_y = 0.42;
  const lowerBoundPos_x = useMemo(() => {
    return targetPosVec_ref.current.x - variance_x / 2;
  }, []);
  const lowerBoundPos_y = useMemo(() => {
    return targetPosVec_ref.current.y - variance_y / 2;
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("IntroAnimationComplete", () => {
      setIsIntroAnimationComplete(true);
    });
    sceneEl.addEventListener("mousemove", updateTargetPosition);
    sceneEl.addEventListener("mouseleave", resetTargetPosition);
    setTimeout(() => {
      isActive_ref.current = true;
    }, 1000);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      sceneEl.removeEventListener("mouseleave", resetTargetPosition);
      sceneEl.removeEventListener("mousemove", updateTargetPosition);
    };
  }, []);

  // reset camera position when tab is reactivated (fixes camera flying off for unkown reason)
  function handleVisibilityChange(e) {
    if (document.visibilityState === "visible") {
      resetTargetPosition();
      camera.position.copy(startPosVec_ref.current);
    }
  }

  useFrame((_state, delta) => {
    if (document.visibilityState !== "visible") return;
    if (
      isIntroAnimationComplete &&
      (Math.abs(camera.position.x - targetPosVec_ref.current.x) > 0.01 || Math.abs(camera.position.y - targetPosVec_ref.current.y) > 0.01) &&
      delta < 0.1
    ) {
      targetPosVec_ref.current.setZ(camera.position.z);
      camera.position.lerp(targetPosVec_ref.current, speed_ref.current * delta);
    }
    camera.lookAt(lookAtPos_ref.current);
  });

  function updateTargetPosition(event) {
    if (!isActive_ref.current) return;

    let normalizedMousePos_x = 1 - event.offsetX / sceneEl.clientWidth;
    let normalizedMousePos_y = 1 - event.offsetY / sceneEl.clientHeight;

    if (Number.isNaN(normalizedMousePos_x) || Number.isNaN(normalizedMousePos_y)) return;

    targetPosVec_ref.current.set(lowerBoundPos_x + variance_x * normalizedMousePos_x, lowerBoundPos_y + variance_y * normalizedMousePos_y, camera.position.z);
  }

  function resetTargetPosition() {
    targetPosVec_ref.current.copy(startPosVec_ref.current);
  }

  return null;
}
