import { useEffect } from "react";
import toastr from "toastr";
import getNetworkSpeed from "@tastetest3d/network-speed";

export default function NetworkError() {
  const _getNetworkSpeed = async () => {
    const speed = await getNetworkSpeed({
      url: "https://network-speed-worker.spatialcinematics.workers.dev/150",
    });
    console.info("NETWORK SPEED:", `${speed}Mbps`);
    if (speed < 4) {
      toastr.warning(`Unstable internet connection.`);
    }
  };

  useEffect(() => {
    _getNetworkSpeed();
    setInterval(() => {
      _getNetworkSpeed();
    }, 60000);
  }, []);

  return null;
}
