import React, { useEffect, useState } from "react";
import { animated, useSpring } from "react-spring";

export const Boop = ({ boopType = "rotation", scale = 1, rotation = 0, timing = 150, children }) => {
  const [isBooped, setIsBooped] = useState(false);

  let appliedTransform;
  if (boopType === "rotation") {
    appliedTransform = isBooped ? `rotate(${rotation}deg)` : `rotate(0deg)`;
  } else if (boopType === "scale") {
    appliedTransform = isBooped ? `scale(${scale})` : `scale(1)`;
  }

  const style = useSpring({
    display: "inline-block",
    height: "fit-content",
    backfaceVisibility: "hidden",
    transform: appliedTransform,
    config: {
      tension: 300,
      friction: 10,
    },
    padding: "2px 0px",
  });

  useEffect(() => {
    if (!isBooped) return;

    const timeoutId = window.setTimeout(() => {
      setIsBooped(false);
    }, timing);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [isBooped, timing]);

  const trigger = () => {
    setIsBooped(true);
  };

  return (
    <animated.span onMouseEnter={trigger} style={style}>
      {children}
    </animated.span>
  );
};
