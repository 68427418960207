import * as React from "react";
import { useProgress } from "@react-three/drei";
import { loading_state } from "../../dataManagers/GlobalDataManagers";
import { useAtom } from "jotai";
// import { useProgress } from '../core/useProgress'

// interface LoaderOptions {
//   containerStyles: any
//   innerStyles: any
//   barStyles: any
//   dataStyles: any
//   dataInterpolation: (p) => string
//   initialState: (active) => boolean
// }

const defaultDataInterpolation = (p) => {
  if (p < 100) return `Applying Engraving...`;
  else return "Loaded 100%";
};
// const defaultDataInterpolation = (p) => `Loaded ${p.toFixed(2)}%`

export function SceneLoadingScreenTransparent({
  containerStyles,
  innerStyles,
  barStyles,
  dataStyles,
  dataInterpolation = defaultDataInterpolation,
  initialState = (active) => active,
}) {
  const { active, progress } = useProgress();
  const progressRef = React.useRef(0);
  const rafRef = React.useRef(0);
  const progressSpanRef = React.useRef(null);
  const [shown, setShown] = React.useState(initialState(active));

  // input by Hayden. Used when we want loading screen for assets not loading by LoadingManager
  // ------------------------------------------------------------------------------------------
  const [forceShown, setForceShown] = React.useState(initialState(active));
  const [loadingState] = useAtom(loading_state);

  React.useEffect(() => {
    if (loadingState) progressRef.current = 0;
    else progressRef.current = 100;
    setForceShown(loadingState);
  }, [loadingState]);

  const customProgress = React.useMemo(() => {
    if (progressRef.current === 100) return 1;
    else if (shown) return 0.5;
    else if (forceShown) return 0;
  }, [shown, forceShown, progressRef.current]);
  // ------------------------------------------------------------------------------------------

  React.useEffect(() => {
    let t;
    if (active !== shown) t = setTimeout(() => setShown(active), 300);
    return () => clearTimeout(t);
  }, [shown, active]);

  const updateProgress = React.useCallback(() => {
    if (!progressSpanRef.current) return;
    progressRef.current += (progress - progressRef.current) / 2;
    if (progressRef.current > 0.95 * progress || progress === 100) progressRef.current = progress;
    progressSpanRef.current.innerText = dataInterpolation(progressRef.current);
    if (progressRef.current < progress) rafRef.current = requestAnimationFrame(updateProgress);
  }, [dataInterpolation, progress]);

  React.useEffect(() => {
    updateProgress();
    return () => cancelAnimationFrame(rafRef.current);
  }, [updateProgress]);

  return shown || forceShown ? (
    // <div style={{ ...styles.container, opacity: active ? 1 : 0, ...containerStyles }}>
    <div style={{ ...styles.container, opacity: 1, ...containerStyles }}>
      <div>
        <div style={{ ...styles.inner, ...innerStyles }}>
          {/* <div style={{ ...styles.bar, transform: `scaleX(${progress / 100})`, ...barStyles }}></div> */}
          <div style={{ ...styles.bar, transform: `scaleX(${customProgress})`, ...barStyles }}></div>
          <span ref={progressSpanRef} style={{ ...styles.data, ...dataStyles }}>
            {" "}
            Loading...{" "}
          </span>
        </div>
      </div>
    </div>
  ) : null;
}

const styles = {
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "opacity 300ms ease",
    zIndex: 1000,
  },
  inner: {
    width: 200,
    height: 3,
    background: "#272727",
    textAlign: "center",
  },
  bar: {
    height: 3,
    width: "100%",
    background: "white",
    transition: "transform 200ms",
    transformOrigin: "left center",
  },
  data: {
    display: "inline-block",
    position: "relative",
    fontVariantNumeric: "tabular-nums",
    marginTop: "0.8em",
    color: "#fff",
    fontSize: "1em",
    fontFamily: `-apple-system, BlinkMacSystemFont, "Inter", "Segoe UI", "Helvetica Neue", Helvetica, Arial, Roboto, Ubuntu, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    whiteSpace: "nowrap",
  },
};
