import { useState, useEffect, useRef, useMemo } from "react";
import { FileDrop } from "react-file-drop";
import { Line } from "../Line/Line";
import { useSpring, animated } from "react-spring";
import _ from "lodash";
import "./styles.scss";

export const FileDragDrop = ({ title, componentsState, itemsStateObj, additionalPrice = 0 }) => {
  const [previewImg, setPreviewImg] = useState();
  const [errorMessage, setErrorMessage] = useState(null);
  const [animationProps, animate] = useSpring(() => ({ from: { opacity: 0, scale: 0 } }));
  const fileInputRef = useRef(null);
  const meshEditingStatus = componentsState.activeObj.mesh_editing_status;
  const activeIdFrontEngraving = itemsStateObj.state.activeIds[componentsState.activeId]?.front?.engraving._id;
  const activeIdRearEngraving = itemsStateObj.state.activeIds[componentsState.activeId]?.rear?.engraving._id;

  // get custom_logo item
  const customLogoItemFront = useMemo(() => {
    return itemsStateObj.state.array.find((item) => item._id === "custom_logo_front");
  }, [itemsStateObj.state.array]);

  const customLogoItemRear = useMemo(() => {
    return itemsStateObj.state.array.find((item) => item._id === "custom_logo_rear");
  }, [itemsStateObj.state.array]);

  useEffect(() => {
    animate.start({ from: { opacity: 0, scale: 0 }, to: { opacity: 1, scale: 1 } });
  }, [meshEditingStatus]);

  // set preview image in drop zone
  useEffect(() => {
    if (meshEditingStatus === "both" || meshEditingStatus === "front") {
      // custom logo is active engraving
      if (activeIdFrontEngraving === "custom_logo_front") {
        if (!_.isEmpty(customLogoItemFront.uploaded_logo_src) && !customLogoItemFront.uploaded_logo_src.includes("temp")) {
          setPreviewImg(customLogoItemFront.uploaded_logo_src);
        } else if (!_.isEmpty(customLogoItemFront.uploaded_logo_base64)) setPreviewImg(customLogoItemFront.uploaded_logo_base64);
      }
      // active engraving is not a custom logo
      else {
        setPreviewImg(null);
      }
    }
    // meshEditingStatus === rear
    else {
      // custom logo is active engraving
      if (activeIdRearEngraving === "custom_logo_rear") {
        if (!_.isEmpty(customLogoItemRear.uploaded_logo_src) && !customLogoItemRear.uploaded_logo_src.includes("temp")) {
          setPreviewImg(customLogoItemRear.uploaded_logo_src);
        } else if (!_.isEmpty(customLogoItemRear.uploaded_logo_base64)) setPreviewImg(customLogoItemRear.uploaded_logo_base64);
      }
      // active engraving is not a custom logo
      else {
        setPreviewImg(null);
      }
    }
  }, [activeIdFrontEngraving, activeIdRearEngraving, meshEditingStatus]);

  const handleOnUpload = (files, event) => {
    // file came from browse button
    if (event.currentTarget.className.includes("browseLocalFiles")) {
      files = event.target.files;
    }

    // only accept png and jpeg files
    const acceptedFileTypes = ["png", "jpeg"];
    if (!acceptedFileTypes.some((el) => files[0].type.includes(el))) {
      setErrorMessage("*Try uploading a PNG or JPEG*");
      return;
    } else {
      setErrorMessage(null);
    }

    // only accept images under this size
    if (files[0]?.size > 5000000) {
      setErrorMessage(`Image is too big. Must be less than 5 MB`);
      return;
    }

    let fileReader = new FileReader();
    // convert to base64
    fileReader.readAsDataURL(files[0]);

    fileReader.onload = () => {
      updateItems(fileReader.result);
      setPreviewImg(fileReader.result);
    };
  };

  const onTargetClick = () => {
    // mimic a click
    fileInputRef.current.click();
  };

  const updateItems = (processedImgFile) => {
    const copy = { ...itemsStateObj.state.activeIds };

    let newActiveObjFront = {};
    let newActiveObjRear = {};

    newActiveObjFront._id = customLogoItemFront._id;
    newActiveObjFront.inputs = customLogoItemFront.inputs;

    newActiveObjRear._id = customLogoItemRear._id;
    newActiveObjRear.inputs = customLogoItemRear.inputs;

    if (meshEditingStatus === "both") {
      customLogoItemFront.uploaded_logo_base64 = processedImgFile;
      newActiveObjFront.inputs.uploaded_logo_src = `temp-${Math.random()}`;

      customLogoItemRear.uploaded_logo_base64 = processedImgFile;
      newActiveObjRear.inputs.uploaded_logo_src = `temp-${Math.random()}`;

      copy[componentsState.activeId].front.engraving = newActiveObjFront;
      copy[componentsState.activeId].rear.engraving = newActiveObjRear;
    } else if (meshEditingStatus === "front") {
      customLogoItemFront.uploaded_logo_base64 = processedImgFile;
      newActiveObjFront.inputs.uploaded_logo_src = `temp-${Math.random()}`;
      copy[componentsState.activeId].front.engraving = newActiveObjFront;
    } else {
      customLogoItemRear.uploaded_logo_base64 = processedImgFile;
      newActiveObjRear.inputs.uploaded_logo_src = `temp-${Math.random()}`;
      copy[componentsState.activeId].rear.engraving = newActiveObjRear;
    }

    itemsStateObj.setActiveId(copy);
  };

  return (
    <animated.div style={animationProps}>
      <div className="d-flex flex-column mb-2">
        <div className="d-flex position-relative">
          {title && <h6 className="mx-auto mb-1">{title}</h6>}
          {additionalPrice != 0 && <h6 className="price position-absolute" style={{ right: "25px" }}>{`$${customLogoItemFront.additional_price}`}</h6>}
        </div>
        {title && <Line color="black" height="1px" width="30px" />}
      </div>
      <div className="FileDragDrop p-3 mb-4">
        <div className="dropContainer">
          <FileDrop onDrop={(files, event) => handleOnUpload(files, event)} onTargetClick={() => onTargetClick()}>
            <div className="d-flex flex-column">
              <img
                className="mt-3 mx-auto"
                src={previewImg ? previewImg : "/images/dragDropPlaceHolderImg.svg"}
                alt="Place Holder"
                style={{ userSelect: "none", pointerEvents: "none" }}
              />
              {errorMessage && <p className="errorMessage mx-auto mt-4">{errorMessage} </p>}
              <p className="my-3 mx-auto">
                Drop your image here or <span className="browseFilesButton">browse</span>
              </p>
              <input
                onChange={(event) => handleOnUpload(null, event)}
                onClick={(event) => {
                  event.target.value = null;
                }}
                ref={fileInputRef}
                accept="image/png,image/jpeg"
                style={{ display: "none" }}
                type="file"
                className="hidden browseLocalFiles"
              />
              <p className="mx-auto">Supports JPG and PNG</p>
            </div>
          </FileDrop>
        </div>
      </div>
    </animated.div>
  );
};
