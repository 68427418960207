import React, { useRef, useEffect, useFrame } from "react";
import { useThree, useLoader } from "@react-three/fiber";
import { Environment } from "@react-three/drei";

import { useGLTFLoader } from "../dataManagers/AssetSystem3d";

// import useShadowHelper from "../../hooks/useShadowHelper.js";

// import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
// import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

export default function EnvironmentController() {
  const camera = useThree(({ camera }) => camera);
  const scene = useThree(({ scene }) => scene);
  const directionalLight = useRef();

  // useShadowHelper(directionalLight)

  useEffect(() => {
    // attach directional light to the camera
    // camera.add(directionalLight.current);
    scene.add(camera);
  }, []);

  // since there's a Suspense wrapping this component, this won't run until after all children components have loaded and rendered
  // signaling to the loading screen we're ready to go
  useEffect(() => {
    document.dispatchEvent(new CustomEvent("EnvironmentAssetsLoaded"));
  }, []);

  // set the background color of the scene
  useEffect(() => {
    document.getElementById("builder-scene-canvas-container").style.background = "black";
  }, []);

  return (
    <>
      <directionalLight
        ref={directionalLight}
        args={["white", 3]}
        position={[0, 1.5, 3]}
        castShadow={true}
        shadow-radius={10}
        shadow-normalBias={0.01}
        shadow-mapSize-width={1024}
        shadow-mapSize-height={1024}
        shadow-camera-near={2.25}
        shadow-camera-far={4.25}
        shadow-camera-left={-1}
        shadow-camera-right={1}
        shadow-camera-top={0.65}
        shadow-camera-bottom={-0.35}
      />

      <ambientLight args={["white", 0.1]} />

      {/* surrounding scene */}
      <FloorModel />
      <BackdropModel />

      {/* environment lighting */}
      <Environment
        background={false}
        files={"/mgp-caliper-assets/scenery/textures/envMaps/night_256.hdr"}
        scene={undefined} // adds the ability to pass a custom THREE.Scene
        // preset={'night'}
      />

      {/* plane to make sure background always stays black (there was a mobile bug where it would turn white) */}
      <mesh position={[0, 3, -3]}>
        <planeGeometry args={[20, 20]} />
        <meshBasicMaterial color={"#000000"} />
      </mesh>
    </>
  );
}

function FloorModel() {
  // load floor model
  const floorModel = useLoader(useGLTFLoader, "/mgp-caliper-assets/scenery/models/floor.glb");

  return <primitive object={floorModel.scene} />;
}

function BackdropModel() {
  // load backdrop model
  const backdropModel = useLoader(useGLTFLoader, "/mgp-caliper-assets/scenery/models/backdrop.glb");

  return <primitive object={backdropModel.scene} />;
}
