const LZUTF8 = require("lzutf8");

export const decodeSynoptiveUrl = (encodedUrl) => {
  // sometimes the synoptive url starts with the url encoded version of .()
  let splitString = encodedUrl.slice(0, 10).includes(".%28%29") ? ".%28%29" : ".()";
  // decode base64 and return byte array
  const decodedBase64 = LZUTF8.decodeBase64(encodedUrl.split(splitString)[1]);
  const decodedByteArray = LZUTF8.decompress(decodedBase64);
  return decodedByteArray;
};
