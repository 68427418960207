import React from "react";
import { BaseColor } from "./BaseColor";
import { Engravings } from "./Engravings";
import { Bolts } from "./Bolts";
import { ShoppingCart } from "../ShoppingCart/ShoppingCart";

// maps component _id to screens
const Components = {
  baseColor_default: BaseColor,
  engravings: Engravings,
  bolts_default: Bolts,
  shopping_cart: ShoppingCart,
};

export const CreateScreens = ({ componentsStateObj, ...props }) => {
  // engravings component id changes (i.e. engravings_bmw) so need to update key to find component
  const componentKey = componentsStateObj.state.activeId.includes("engravings") ? "engravings" : componentsStateObj.state.activeId;

  // make sure we have a valid component coming from components.json
  if (typeof Components[componentKey] !== "undefined") {
    // create and render the component depending on activeObj.react.component
    return React.createElement(Components[componentKey], {
      key: componentsStateObj.state.activeId,
      componentsStateObj,
      ...props,
    });
  }
  // component wasn't found
  return React.createElement(() => <div>The component {componentsStateObj.state.activeId} has not been created yet.</div>, {
    key: Math.floor(Math.random() * 100),
  });
};
