import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import EmbedController from "../../../../embed/EmbedController";
import { getStepperActiveIndex } from "../../../modules/getStepperActiveIndex";
import "./styles.scss";

// stepper styles
const ipad = 767;
const md = 769;

const useColorlibStepIconStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#777", // black
    zIndex: 1,
    borderRadius: "50%",
    cursor: "pointer",
    width: 20,
    height: 20,
    boxShadow: "0 4px 10px 0 rgba(50,50,50,.5)", // black
    "&:hover": {
      backgroundColor: "#c71608", // red
    },
    [theme.breakpoints.up(ipad)]: {
      width: 20,
      height: 20,
    },
    [theme.breakpoints.up(md)]: {
      width: 20,
      height: 20,
    },
  },
  active: {
    backgroundColor: "#c71608", // red
    boxShadow: "0 4px 10px 0 rgba(230,36,41,.5)",
  },
  stepCountActive: {
    color: "#F9F6EE", // red
    fontSize: "12px",
    marginTop: "1px",
  },
  stepCount: {
    color: "white",
    fontSize: "12px",
    marginTop: "1px",
    "&:hover": {
      color: "#F9F6EE", // red
    },
  },
}));

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed, icon: step } = props;
  return (
    <div
      className={`${clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })} text-center`}
    >
      {!active && (
        <p className={`no-user-select ${active ? classes.stepCountActive : classes.stepCount}`}>
          {completed ? <img style={{ width: "12px", height: "12px" }} src="/images/checkmarkWhite.svg" alt="check" /> : step}
        </p>
      )}
    </div>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

export default function CustomStepper({ stepperData, setStepper }) {
  const handleClick = (component) => {
    setStepper(component._id);

    if (EmbedController.isEmbedded) {
      EmbedController.sendGTMAnalyticsEvent({
        event: "Navigation Stepper Click - MGP",
        stepperLabel: component.displayName,
        page: stepperData.activeId.split("_")[0],
      });
    }
  };

  return (
    <Row className="bg-white">
      <Col>
        <Stepper className="Stepper p-0 mb-2" alternativeLabel activeStep={getStepperActiveIndex(stepperData)}>
          {stepperData.array.map((component, index) => {
            if (component._id.includes("cart")) return null;
            const isActiveStep = getStepperActiveIndex(stepperData) === index;
            return (
              <Step
                key={index}
                onClick={() => {
                  handleClick(component);
                }}
              >
                <StepLabel icon={index + 1} StepIconComponent={ColorlibStepIcon}>
                  {isActiveStep ? `Step ${index + 1}` : component.displayName}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Col>
    </Row>
  );
}

CustomStepper.propTypes = {
  /**
   * Contains labels and activeIndex
   */
  stepperData: PropTypes.object,
  /**
   * Contains an array of colors for each step
   */
  stepColors: PropTypes.array,

  /**
   * Updates stepper activeIndex when clicked
   */
  setStepper: PropTypes.func,
};
