/**
 * Calculates the final price of the configuration
 */

export const getFinalPrice = (productsState, cartData) => {
  let finalPrice = 0;
  cartData.forEach((item, index) => {
    if (item && isNumber(item.price)) finalPrice += item.price;
  });
  return parseFloat((finalPrice += productsState.activeObj.base_price))?.toFixed(2);
};

function isNumber(num) {
  if (typeof num === "number" && !isNaN(num)) {
    return true;
  }
  return false;
}
