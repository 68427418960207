import { useCallback, useMemo } from "react";
import { RectangleButton } from "../RectangleButton/RectangleButton";
import { getStepperActiveIndex } from "../../../modules/getStepperActiveIndex";
import EmbedController from "../../../../embed/EmbedController";

export const BottomNavigation = ({ componentsStateObj, handleAddToCartClick }) => {
  const activeComponentIndex = getStepperActiveIndex(componentsStateObj.state);

  const handlePrevious = useCallback(() => {
    if (activeComponentIndex !== 0) {
      componentsStateObj.setActiveId(componentsStateObj.state.array[activeComponentIndex - 1]._id);

      if (EmbedController.isEmbedded) {
        EmbedController.sendGTMAnalyticsEvent({
          event: "Bottom Navigation Button Click - MGP",
          flagLabel: "Previous",
          page: componentsStateObj.state.activeId.split("_")[0],
        });
      }
    }
  }, [componentsStateObj.state.activeId]);

  const handleNext = useCallback(() => {
    if (activeComponentIndex > 2) {
      handleAddToCartClick();
      return;
    }
    componentsStateObj.setActiveId(componentsStateObj.state.array[activeComponentIndex + 1]._id);

    if (EmbedController.isEmbedded) {
      EmbedController.sendGTMAnalyticsEvent({
        event: "Bottom Navigation Button Click - MGP",
        flagLabel: "Next",
        page: componentsStateObj.state.activeId.split("_")[0],
      });
    }
  }, [activeComponentIndex, componentsStateObj, handleAddToCartClick]);


  const nextLabel = useMemo(() => {
    switch (activeComponentIndex) {
      case 2: return "Cart";
      case 3: return "Add to cart";
      default: return "Next";
    }
  }, [activeComponentIndex]);

  return (
    <div className="d-flex justify-content-around my-5">
      <RectangleButton
        label="Prev"
        buttonType="default"
        positionStyle="top-0"
        onClickHandler={handlePrevious}
        customClass={`bottomNavigationBtn ${activeComponentIndex === 0 && `disabled`}`}
      />
      <RectangleButton
        label={nextLabel}
        buttonType="default"
        positionStyle="top-0"
        onClickHandler={handleNext}
        customClass="bottomNavigationBtn"
      />
    </div>
  );
};
